import React from 'react'
import { NavLink } from 'react-router-dom'
import { withTranslation } from 'react-i18next'
import { Row, Col } from 'reactstrap'
import { connect } from 'react-redux'
import { compose } from 'redux'

import SidebarRoutes from './SidebarRoutes'
import { mainLogo } from '../../helpers/logo'
import { LogoEleneo } from 'components/common/LogoEleneo'

const Sidebar = (props) => {
  const { t } = props
  return (
    <Row>
      <Col className="sidebar">
        <Row>
          <Col
            className="px-0"
            css={{
              height: 138,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {props.logo ? (
              <NavLink to="/" className="logo">
                <img src={props.logo} alt={t('sidebar.logoAlt')} className="img-fluid" />
              </NavLink>
            ) : (
              <div
                css={{
                  padding: '1.5rem 1rem',
                  height: '100%',
                }}
              >
                <div
                  css={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'flex-end',
                  }}
                >
                  <NavLink
                    to="/"
                    css={{
                      color: '#f8f9fa',
                      lineHeight: 1.1,
                      marginBottom: '.15rem',
                      '&:hover': {
                        textDecoration: 'none',
                        color: '#f8f9fa',
                      },
                    }}
                  >
                    <div
                      css={{
                        fontFamily: 'Merlo',
                        fontSize: '3.6rem',
                      }}
                    >
                      eView
                    </div>

                    <div
                      css={{
                        fontSize: '1.1rem',
                        letterSpacing: '.5px',
                      }}
                    >
                      Premium
                    </div>
                  </NavLink>

                  <div>
                    <span
                      css={{
                        color: '#f8f9fa',
                        fontSize: '.7rem',
                        lineHeight: 1,
                        margin: '0 0.4rem 0 0.9rem',
                      }}
                    >
                      by
                    </span>

                    <a href="https://www.eleneo.fr/" target="_blank" rel="noopener noreferrer">
                      <LogoEleneo color="#f8f9fa" fill="#adb5bd" style={{ fontSize: '1.2rem' }} />
                    </a>
                  </div>
                </div>
              </div>
            )}
          </Col>
        </Row>
        <Row>
          <Col>
            <SidebarRoutes />
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

export default compose(
  withTranslation(),
  connect((state) => ({ logo: mainLogo(state) }))
)(Sidebar)
