import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { withRouter, Switch, Route, Redirect } from 'react-router-dom'
import { getUor } from 'store/selectors'

import * as user from 'commons'
import PowerConsumptionView from '../views/PowerConsumptionView'
import ExpensesView from '../views/ExpensesView'
import BillsView from '../views/BillsView'
import ContractsView from '../views/ContractsView'
import MarketInformationsView from '../views/MarketInformationsView'
import MarketAdminView from '../views/MarketAdminView'
import { SitesView } from 'components/views/SitesView'
import { ComexView } from 'components/views/ComexView'

const Views = ({ isAdmin, uor }) => {
  return (
    <Switch>
      <Route path="/power-consumption/:filter?" component={PowerConsumptionView} />
      <Route path="/expenses" component={ExpensesView} />
      <Route path="/bills" component={BillsView} />
      <Route path="/contracts" component={ContractsView} />
      <Route path="/market-informations" component={MarketInformationsView} />
      <Route path="/sites" component={SitesView} />

      {uor === '620' && <Route path="/comex" component={ComexView} />}

      <Route
        path="/market-admin"
        render={() => (isAdmin ? <MarketAdminView /> : <Redirect to="/" push />)}
      />
    </Switch>
  )
}

const mapStateToProps = (state) => ({
  isAdmin: user.isAdmin(state),
  uor: getUor(state),
})

Views.propTypes = {
  isAdmin: PropTypes.bool.isRequired,
}

export default withRouter(connect(mapStateToProps)(Views))
