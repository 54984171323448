import v4 from 'uuid/v4'
import * as user from 'commons'
import * as kpi from '../../api/kpi.js'
import * as graph from '../../api/graph.js'
import {
  getKpiGasConsoUuid,
  getKpiGasExpenseUuid,
  getKpiGasPriceUuid,
  getGasGraphUuid,
} from '../../reducers/powerConsumption/gas'
import { getStartDate, getEndDate } from '../../reducers/dateRange'
import { getCurrentVisibleGasPeesIds } from '../../reducers/currentSelection'
import { getTypeDonnee } from '../../reducers/typeDonnee'
import { getGraphUnite } from '../../reducers/powerConsumption'
import { getGasGraphAgregation } from '../../reducers/powerConsumption/gas'
import { updateView } from '../views'

export const fetchKpiConso = () => (dispatch, getState) => {
  const uuid = v4()

  dispatch({
    type: 'FETCH_GAS_KPI_CONSO_REQUEST',
    uuid,
  })

  const dateDebut = getStartDate(getState())
  const dateFin = getEndDate(getState())
  const perimetre = getCurrentVisibleGasPeesIds(getState()).join(';')
  const type = getTypeDonnee(getState()).join(';')
  const uorIdMst = user.uorIdMst(getState())
  const lanId = user.lanId(getState())

  return kpi
    .fetchKpi(
      uuid,
      dateDebut,
      dateFin,
      perimetre,
      'Volume',
      type,
      12,
      21,
      '',
      '',
      '',
      uorIdMst,
      lanId
    )
    .then(
      (response) => {
        if (response && response.headers.get('X-REQUEST-ID') === getKpiGasConsoUuid(getState())) {
          response.json().then((response) =>
            dispatch({
              type: 'FETCH_GAS_KPI_CONSO_SUCCESS',
              response,
            })
          )
        }
      },
      (error) => {
        dispatch({
          type: 'FETCH_GAS_KPI_CONSO_FAILURE',
          message: error.message || 'Something went wrong.',
        })
      }
    )
}

export const fetchKpiExpense = () => (dispatch, getState) => {
  const uuid = v4()

  dispatch({
    type: 'FETCH_GAS_KPI_EXPENSE_REQUEST',
    uuid,
  })

  const dateDebut = getStartDate(getState())
  const dateFin = getEndDate(getState())
  const perimetre = getCurrentVisibleGasPeesIds(getState()).join(';')
  const type = getTypeDonnee(getState()).join(';')
  const uorIdMst = user.uorIdMst(getState())
  const lanId = user.lanId(getState())

  return kpi
    .fetchKpi(
      uuid,
      dateDebut,
      dateFin,
      perimetre,
      'Depense',
      type,
      12,
      21,
      '',
      '',
      '',
      uorIdMst,
      lanId
    )
    .then(
      (response) => {
        if (response && response.headers.get('X-REQUEST-ID') === getKpiGasExpenseUuid(getState())) {
          response.json().then((response) =>
            dispatch({
              type: 'FETCH_GAS_KPI_EXPENSE_SUCCESS',
              response,
            })
          )
        }
      },
      (error) => {
        dispatch({
          type: 'FETCH_GAS_KPI_EXPENSE_FAILURE',
          message: error.message || 'Something went wrong.',
        })
      }
    )
}

export const fetchKpiPrice = () => (dispatch, getState) => {
  const uuid = v4()

  dispatch({
    type: 'FETCH_GAS_KPI_PRICE_REQUEST',
    uuid,
  })

  const dateDebut = getStartDate(getState())
  const dateFin = getEndDate(getState())
  const perimetre = getCurrentVisibleGasPeesIds(getState()).join(';')
  const type = getTypeDonnee(getState()).join(';')
  const uorIdMst = user.uorIdMst(getState())
  const lanId = user.lanId(getState())

  return kpi
    .fetchKpi(
      uuid,
      dateDebut,
      dateFin,
      perimetre,
      'Prix',
      type,
      12,
      21,
      '',
      '',
      '',
      uorIdMst,
      lanId
    )
    .then(
      (response) => {
        if (response && response.headers.get('X-REQUEST-ID') === getKpiGasPriceUuid(getState())) {
          response.json().then((response) =>
            dispatch({
              type: 'FETCH_GAS_KPI_PRICE_SUCCESS',
              response,
            })
          )
        }
      },
      (error) => {
        dispatch({
          type: 'FETCH_GAS_KPI_PRICE_FAILURE',
          message: error.message || 'Something went wrong.',
        })
      }
    )
}

export const fetchGraph = (typesUpdated) => (dispatch, getState) => {
  const uuid = v4()

  const state = getState()

  const dateDebut = getStartDate(state)
  const dateFin = getEndDate(state)
  const perimetre = getCurrentVisibleGasPeesIds(state)
  const type = 'FacturePrevu;Facture;FactureN1;Budget' // getTypeDonnee(state).join(';')
  const unite = getGraphUnite(state)
  const agregation = getGasGraphAgregation(state)
  const uorIdMst = user.uorIdMst(state)
  const lanId = user.lanId(state)

  if (typesUpdated && agregation === '2') return

  dispatch({
    type: 'FETCH_GAS_GRAPH_REQUEST',
    uuid,
  })

  if (agregation === '2') {
    return graph
      .fetchTemporalGraph(
        uuid,
        dateDebut,
        dateFin,
        perimetre.join(';'),
        'Volume',
        type,
        '',
        unite,
        '',
        '',
        '',
        '',
        agregation,
        uorIdMst,
        lanId
      )
      .then(
        (response) => {
          if (response && response.headers.get('X-REQUEST-ID') === getGasGraphUuid(getState())) {
            response.json().then((response) =>
              dispatch({
                type: 'FETCH_GAS_GRAPH_SUCCESS',
                response,
              })
            )
          }
        },
        (error) => {
          dispatch({
            type: 'FETCH_GAS_GRAPH_FAILURE',
            message: error.message || 'Something went wrong.',
          })
        }
      )
  }

  const payload = {
    Type: agregation === '1' ? 'SIT' : 'CPT',
    Ids: perimetre,
    Debut: dateDebut,
    Fin: dateFin,
    Commodite: 'GAZ',
    TypeDonnees: {
      FacturePrevu: true,
      Facture: true,
      FactureN1: true,
      Budget: true,
    },
  }

  return graph.fetchConsoGraph(uuid, payload).then(
    (response) => {
      if (response && response.headers.get('X-REQUEST-ID') === getGasGraphUuid(getState())) {
        response.json().then((response) =>
          dispatch({
            type: 'FETCH_GAS_GRAPH_SUCCESS',
            response: { series: response },
          })
        )
      }
    },
    (error) => {
      dispatch({
        type: 'FETCH_GAS_GRAPH_FAILURE',
        message: error.message || 'Something went wrong.',
      })
    }
  )
}

export const updateGasGraphAgregation = (value) => (dispatch) => {
  dispatch({
    type: 'UPDATE_GAS_GRAPH_AGREGATION',
    value,
  })
  dispatch(updateView())
}

export const setGasTooMuchPees = (value) => (dispatch) => {
  dispatch({
    type: 'SET_GAS_TOO_MUCH_PEES',
    value,
  })

  if (value) {
    dispatch(updateGasGraphAgregation('2'))
  }
}
