import React, { useMemo, useRef } from 'react'
import { useSelector } from 'react-redux'
import ReactTable from 'react-table'
import moment from 'moment'
import { getDate } from 'store/comex'
import { useDepenses } from './useDepenses'
import { LoaderCenter, TableLoader } from 'components/common/Loader'
import mapValues from 'lodash/mapValues'
import { formatPercent, formatNoDecimal, formatDecimal } from './format'
import CSVButton from '../common/CSVButton'
import { PercentCell } from './PercentCell'
import sum from 'lodash/sum'
import sumBy from 'lodash/sumBy'

const getStart = (m) =>
  m
    .clone()
    .startOf('year')
    .format('YYYY-MM-DD')

const getEnd = (m) =>
  m
    .clone()
    .endOf('year')
    .startOf('month')
    .format('YYYY-MM-DD')

const divise = (a, b) => (b === 0 || b == null || a == null ? null : a / b)
const multiply = (a, b) => (b == null || a == null ? null : a * b)

export const FullYear = () => {
  const tableRef = useRef()

  const date = useSelector(getDate)
  const year = Number(date.slice(0, 4))
  const n = moment(date, 'YYYY-MM')
  const n1 = n.clone().subtract(1, 'year')

  const queryReel = useDepenses({ type: 'actualise', start: getStart(n), end: getEnd(n) })
  const queryBudget = useDepenses({ type: 'budget', start: getStart(n), end: getEnd(n) })
  const queryN1 = useDepenses({ type: 'actualise', start: getStart(n1), end: getEnd(n1) })

  const columns = useMemo(() => {
    if (!queryReel.data || !queryBudget.data || !queryN1.data) return []
    return [
      {
        Header: '',
        accessor: 'title',
        width: 260,
        Cell: (props) => (
          <div
            css={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              fontSize: props.original.type === 'title' ? '1.1em' : '1em',
              color: props.original.type === 'title' ? 'var(--primary)' : 'inherit',
            }}
            title={`${props.value} (${props.original.description})`}
          >
            <span style={{ fontWeight: props.original.type === 'title' ? 'bold' : 'normal' }}>
              {props.value}
            </span>
            {'\u2000'}
            <small className="text-muted">{props.original.description}</small>
          </div>
        ),
      },
      ...Object.keys({ ...queryReel.data, ...queryBudget.data, ...queryN1.data }).map(
        (pays, index) => ({
          Header: pays,
          title: pays,
          accessor: pays,
          Cell: (props) => <ValueCell {...props} index={index} />,
        })
      ),
      {
        Header: 'Total',
        accessor: 'total',
        Cell: (props) => <ValueCell {...props} index={null} />,
      },
    ]
  }, [queryReel.data, queryBudget.data, queryN1.data])

  const data = useMemo(() => {
    if (!queryReel.data || !queryBudget.data || !queryN1.data) return []

    /* Atterrissage */

    const consoAtt = mapValues(queryReel.data, 'VolumeTotal')
    const totalConsoAtt = sum(Object.values(consoAtt))

    const depenseAtt = mapValues(queryReel.data, (item) => item.DepenseTotale / 1000)
    const totalDepenseAtt = sum(Object.values(depenseAtt))

    const prixAtt = mapValues(queryReel.data, 'PrixTotal')
    const totalPrixAtt = multiply(divise(totalDepenseAtt, totalConsoAtt), 1000)

    const energieAtt = mapValues(queryReel.data, (item) =>
      divise(item.DepenseNrj, item.DepenseTotale)
    )
    const totalEnergieAtt = divise(
      sumBy(Object.values(queryReel.data), 'DepenseNrj'),
      sumBy(Object.values(queryReel.data), 'DepenseTotale')
    )

    const transportAtt = mapValues(queryReel.data, (item) =>
      divise(item.DepenseTransport, item.DepenseTotale)
    )
    const totalTransportAtt = divise(
      sumBy(Object.values(queryReel.data), 'DepenseTransport'),
      sumBy(Object.values(queryReel.data), 'DepenseTotale')
    )

    const taxesAtt = mapValues(queryReel.data, (item) =>
      divise(item.DepenseTaxes, item.DepenseTotale)
    )
    const totalTaxesAtt = divise(
      sumBy(Object.values(queryReel.data), 'DepenseTaxes'),
      sumBy(Object.values(queryReel.data), 'DepenseTotale')
    )

    /* Budget */

    const consoBud = mapValues(queryBudget.data, 'VolumeTotal')
    const totalConsoBud = sum(Object.values(consoBud))

    const depenseBud = mapValues(queryBudget.data, (item) => item.DepenseTotale / 1000)
    const totalDepenseBud = sum(Object.values(depenseBud))

    const prixBud = mapValues(queryBudget.data, 'PrixTotal')
    const totalPrixBud = multiply(divise(totalDepenseBud, totalConsoBud), 1000)

    const energieBud = mapValues(queryBudget.data, (item) =>
      divise(item.DepenseNrj, item.DepenseTotale)
    )
    const totalEnergieBud = divise(
      sumBy(Object.values(queryBudget.data), 'DepenseNrj'),
      sumBy(Object.values(queryBudget.data), 'DepenseTotale')
    )

    const transportBud = mapValues(queryBudget.data, (item) =>
      divise(item.DepenseTransport, item.DepenseTotale)
    )
    const totalTransportBud = divise(
      sumBy(Object.values(queryBudget.data), 'DepenseTransport'),
      sumBy(Object.values(queryBudget.data), 'DepenseTotale')
    )

    const taxesBud = mapValues(queryBudget.data, (item) =>
      divise(item.DepenseTaxes, item.DepenseTotale)
    )
    const totalTaxesBud = divise(
      sumBy(Object.values(queryBudget.data), 'DepenseTaxes'),
      sumBy(Object.values(queryBudget.data), 'DepenseTotale')
    )

    /* Année N-1 */

    const consoN1 = mapValues(queryN1.data, 'VolumeTotal')
    const totalConsoN1 = sum(Object.values(consoN1))

    const depenseN1 = mapValues(queryN1.data, (item) => item.DepenseTotale / 1000)
    const totalDepenseN1 = sum(Object.values(depenseN1))

    const prixN1 = mapValues(queryN1.data, 'PrixTotal')
    const totalPrixN1 = multiply(divise(totalDepenseN1, totalConsoN1), 1000)

    const energieN1 = mapValues(queryN1.data, (item) => divise(item.DepenseNrj, item.DepenseTotale))
    const totalEnergieN1 = divise(
      sumBy(Object.values(queryN1.data), 'DepenseNrj'),
      sumBy(Object.values(queryN1.data), 'DepenseTotale')
    )

    const transportN1 = mapValues(queryN1.data, (item) =>
      divise(item.DepenseTransport, item.DepenseTotale)
    )
    const totalTransportN1 = divise(
      sumBy(Object.values(queryN1.data), 'DepenseTransport'),
      sumBy(Object.values(queryN1.data), 'DepenseTotale')
    )

    const taxesN1 = mapValues(queryN1.data, (item) => divise(item.DepenseTaxes, item.DepenseTotale))
    const totalTaxesN1 = divise(
      sumBy(Object.values(queryN1.data), 'DepenseTaxes'),
      sumBy(Object.values(queryN1.data), 'DepenseTotale')
    )

    /* Ecarts et impacts */

    const ecartVolumeBudget = Object.entries(queryBudget.data).reduce(
      (acc, [key, item]) => ({
        ...acc,
        [key]:
          queryReel.data[key] != null ? queryReel.data[key].VolumeTotal - item.VolumeTotal : null,
      }),
      {}
    )
    const totalEcartVolumeBudget = sum(Object.values(ecartVolumeBudget))

    const impactVolumeBudget = Object.entries(queryBudget.data).reduce(
      (acc, [key, item]) => ({
        ...acc,
        [key]: (queryReel.data[key].VolumeTotal - item.VolumeTotal) * item.PrixTotal,
      }),
      {}
    )
    const totalImpactVolumeBudget = sum(Object.values(impactVolumeBudget))

    const ecartDepenseBudget = Object.entries(queryReel.data).reduce(
      (acc, [key, item]) => ({
        ...acc,
        [key]:
          queryBudget.data[key] != null ? item.PrixTotal - queryBudget.data[key].PrixTotal : null,
      }),
      {}
    )
    const totalEcartDepenseBudget = sum(Object.values(ecartDepenseBudget))

    const impactPrixBudget = Object.entries(queryReel.data).reduce(
      (acc, [key, item]) => ({
        ...acc,
        [key]: item.PrixTotal - queryBudget.data[key].PrixTotal - impactVolumeBudget[key],
      }),
      {}
    )
    const totalImpactPrixBudget = sum(Object.values(impactPrixBudget))

    const ecartVolumeN1 = Object.entries(queryN1.data).reduce(
      (acc, [key, item]) => ({
        ...acc,
        [key]:
          queryReel.data[key] != null ? queryReel.data[key].VolumeTotal - item.VolumeTotal : null,
      }),
      {}
    )
    const totalEcartVolumeN1 = sum(Object.values(ecartVolumeN1))

    const impactVolumeN1 = Object.entries(queryN1.data).reduce(
      (acc, [key, item]) => ({
        ...acc,
        [key]:
          queryReel.data[key] != null
            ? (queryReel.data[key].VolumeTotal - item.VolumeTotal) * item.PrixTotal
            : null,
      }),
      {}
    )
    const totalImpactVolumeN1 = sum(Object.values(impactVolumeN1))

    const ecartDepenseN1 = Object.entries(queryReel.data).reduce(
      (acc, [key, item]) => ({
        ...acc,
        [key]: queryN1.data[key] != null ? item.PrixTotal - queryN1.data[key].PrixTotal : null,
      }),
      {}
    )
    const totalEcartDepenseN1 = sum(Object.values(ecartDepenseN1))

    const impactPrixN1 = Object.entries(queryReel.data).reduce(
      (acc, [key, item]) => ({
        ...acc,
        [key]:
          queryReel.data[key] != null
            ? item.PrixTotal - queryN1.data[key].PrixTotal - impactVolumeN1[key]
            : null,
      }),
      {}
    )
    const totalImpactPrixN1 = sum(Object.values(impactPrixN1))

    return [
      { title: 'Atterrissage', type: 'title' },
      {
        title: 'Consommation (MWh)',
        type: 'nodecimal',
        ...consoAtt,
        total: totalConsoAtt,
      },
      {
        title: 'Dépense (k€)',
        type: 'nodecimal',
        unite: 'k€',
        ...depenseAtt,
        total: totalDepenseAtt,
      },
      {
        title: 'Prix (€/MWh)',
        type: 'decimal',
        ...prixAtt,
        total: totalPrixAtt,
      },
      {
        title: '% énergie',
        type: 'percent',
        ...energieAtt,
        total: totalEnergieAtt,
      },
      {
        title: '% transport',
        type: 'percent',
        ...transportAtt,
        total: totalTransportAtt,
      },
      {
        title: '% taxes',
        type: 'percent',
        ...taxesAtt,
        total: totalTaxesAtt,
      },
      { title: 'Budget', type: 'title' },
      {
        title: 'Consommation (MWh)',
        type: 'nodecimal',
        ...consoBud,
        total: totalConsoBud,
      },
      {
        title: 'Dépense (k€)',
        type: 'nodecimal',
        unite: 'k€',
        ...depenseBud,
        total: totalDepenseBud,
      },
      {
        title: 'Prix (€/MWh)',
        type: 'decimal',
        ...prixBud,
        total: totalPrixBud,
      },
      {
        title: '% énergie',
        type: 'percent',
        ...energieBud,
        total: totalEnergieBud,
      },
      {
        title: '% transport',
        type: 'percent',
        ...transportBud,
        total: totalTransportBud,
      },
      {
        title: '% taxes',
        type: 'percent',
        ...taxesBud,
        total: totalTaxesBud,
      },
      { title: 'Atterrissage vs Budget', type: 'title' },
      {
        title: `Ecart volume atterrissage/budget (MWh)`,
        type: 'nodecimal',
        ...ecartVolumeBudget,
        total: totalEcartVolumeBudget,
      },
      {
        title: `Ecart dépense atterrissage/budget (k€)`,
        type: 'decimal',
        unite: 'k€',
        ...ecartDepenseBudget,
        total: totalEcartDepenseBudget,
      },
      {
        title: 'Impact volume (k€)',
        type: 'nodecimal',
        unite: 'k€',
        ...impactVolumeBudget,
        total: totalImpactVolumeBudget,
      },
      {
        title: 'Impact prix (k€)',
        type: 'nodecimal',
        unite: 'k€',
        ...impactPrixBudget,
        total: totalImpactPrixBudget,
      },
      { title: `${year - 1}`, type: 'title' },
      { title: 'Consommation (MWh)', type: 'decimal', ...consoN1 },
      {
        title: 'Dépense (k€)',
        type: 'nodecimal',
        unite: 'k€',
        ...depenseN1,
        total: totalDepenseN1,
      },
      {
        title: 'Prix (€/MWh)',
        type: 'decimal',
        ...prixN1,
        total: totalPrixN1,
      },
      {
        title: '% énergie',
        type: 'percent',
        ...energieN1,
        total: totalEnergieN1,
      },
      {
        title: '% transport',
        type: 'percent',
        ...transportN1,
        total: totalTransportN1,
      },
      {
        title: '% taxes',
        type: 'percent',
        ...taxesN1,
        total: totalTaxesN1,
      },
      { title: `Atterrissage vs ${year - 1}`, type: 'title' },
      {
        title: `Ecart volume atterrissage/${year - 1} (MWh)`,
        type: 'nodecimal',
        ...ecartVolumeN1,
        total: totalEcartVolumeN1,
      },
      {
        title: `Ecart dépense atterrissage/${year - 1} (k€)`,
        type: 'decimal',
        unite: 'k€',
        ...ecartDepenseN1,
        total: totalEcartDepenseN1,
      },
      {
        title: 'Impact volume (k€)',
        type: 'nodecimal',
        unite: 'k€',
        ...impactVolumeN1,
        total: totalImpactVolumeN1,
      },
      {
        title: 'Impact prix (k€)',
        type: 'nodecimal',
        unite: 'k€',
        ...impactPrixN1,
        totalImpactPrixN1,
      },
    ]
  }, [queryReel.data, queryBudget.data, year, queryN1.data])

  if (queryReel.isLoading || queryBudget.isLoading) return <LoaderCenter />

  const createCSVContent = () => {
    const { data, columns } = tableRef.current.getResolvedState()
    const paysList = columns.slice(1).map((col) => col.title)
    const header = ['', ...paysList]
    const body = data.map((item) => [
      item.title,
      ...paysList.map((pays) => {
        const value = item[pays]
        if (item.type === 'percent') return formatPercent(value, 0, true)
        if (item.type === 'nodecimal') return formatNoDecimal(value, true)
        if (item.type === 'decimal') return formatDecimal(value, true)
        return value
      }),
    ])
    return [header, ...body]
  }

  if (!data || data.length === 0) return null

  return (
    <div>
      <span css={{ position: 'absolute', top: 0, right: 0 }}>
        <CSVButton filename={`Année ${year}.csv`} createContent={createCSVContent} />
      </span>

      <ReactTable
        ref={tableRef}
        data={data}
        columns={columns}
        sortable={false}
        showPagination={false}
        loading={queryReel.isFetching || queryBudget.isFetching}
        LoadingComponent={TableLoader}
        minRows={0}
        pageSize={data.length}
        css={{
          '.rt-thead': {
            zIndex: 10,
          },
          '.rt-th': {
            padding: '8px 10px !important',
          },
          '.rt-td': {
            position: 'relative',
          },
          '.rt-th:nth-of-type(even), .rt-td:nth-of-type(even)': {
            backgroundColor: 'rgba(0,0,0,0.04)',
          },
          '.rt-th:last-of-type, .rt-td:last-of-type': {
            backgroundColor: 'rgba(0,0,0,0.08)',
          },
        }}
      />
    </div>
  )
}

const ValueCell = (props) => {
  const index =
    ['% énergie', '% transport', '% taxes'].findIndex((title) => title === props.original.title) + 1

  switch (props.original.type) {
    case 'title':
      return null
    case 'decimal':
      return (
        <div className="text-right">
          {formatDecimal(props.value)}
          {props.value != null && props.original.unite && ` ${props.original.unite}`}
        </div>
      )
    case 'nodecimal':
      return (
        <div className="text-right">
          {formatNoDecimal(props.value)}
          {props.value != null && props.original.unite && ` ${props.original.unite}`}
        </div>
      )
    case 'percent':
      return <PercentCell value={props.value} index={index || null} />
    default:
      return props.value
  }
}
