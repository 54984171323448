import v4 from 'uuid/v4'
import * as user from 'commons'
import * as kpi from '../../api/kpi.js'
import * as graph from '../../api/graph.js'
import {
  getKpiElecConsoUuid,
  getKpiElecExpenseUuid,
  getKpiElecPriceUuid,
  getElecGraphUuid,
  getElecCumulativeUuid,
  getElecCumulativeTemporalUuid,
  getElecGraphAgregation,
} from '../../reducers/powerConsumption/electricity'
import { getStartDate, getEndDate } from '../../reducers/dateRange'
import { getCurrentVisibleElecPeesIds } from '../../reducers/currentSelection'
import { getTypeDonnee } from '../../reducers/typeDonnee'
import { getGraphUnite } from '../../reducers/powerConsumption'
import { updateView } from '../views'

export const fetchKpiConso = () => (dispatch, getState) => {
  const uuid = v4()

  dispatch({
    type: 'FETCH_ELECTRICITY_KPI_CONSO_REQUEST',
    uuid,
  })

  const dateDebut = getStartDate(getState())
  const dateFin = getEndDate(getState())
  const perimetre = getCurrentVisibleElecPeesIds(getState()).join(';')
  const type = getTypeDonnee(getState()).join(';')
  const uorIdMst = user.uorIdMst(getState())
  const lanId = user.lanId(getState())

  return kpi
    .fetchKpi(
      uuid,
      dateDebut,
      dateFin,
      perimetre,
      'Volume',
      type,
      12,
      21,
      '',
      '',
      '',
      uorIdMst,
      lanId
    )
    .then(
      (response) => {
        if (response && response.headers.get('X-REQUEST-ID') === getKpiElecConsoUuid(getState())) {
          response.json().then((response) =>
            dispatch({
              type: 'FETCH_ELECTRICITY_KPI_CONSO_SUCCESS',
              response,
            })
          )
        }
      },
      (error) => {
        dispatch({
          type: 'FETCH_ELECTRICITY_KPI_CONSO_FAILURE',
          message: error.message || 'Something went wrong.',
        })
      }
    )
}

export const fetchKpiExpense = () => (dispatch, getState) => {
  const uuid = v4()

  dispatch({
    type: 'FETCH_ELECTRICITY_KPI_EXPENSE_REQUEST',
    uuid,
  })

  const dateDebut = getStartDate(getState())
  const dateFin = getEndDate(getState())
  const perimetre = getCurrentVisibleElecPeesIds(getState()).join(';')
  const type = getTypeDonnee(getState()).join(';')
  const uorIdMst = user.uorIdMst(getState())
  const lanId = user.lanId(getState())

  return kpi
    .fetchKpi(
      uuid,
      dateDebut,
      dateFin,
      perimetre,
      'Depense',
      type,
      12,
      21,
      '',
      '',
      '',
      uorIdMst,
      lanId
    )
    .then(
      (response) => {
        if (
          response &&
          response.headers.get('X-REQUEST-ID') === getKpiElecExpenseUuid(getState())
        ) {
          response.json().then((response) =>
            dispatch({
              type: 'FETCH_ELECTRICITY_KPI_EXPENSE_SUCCESS',
              response,
            })
          )
        }
      },
      (error) => {
        dispatch({
          type: 'FETCH_ELECTRICITY_KPI_EXPENSE_FAILURE',
          message: error.message || 'Something went wrong.',
        })
      }
    )
}

export const fetchKpiPrice = () => (dispatch, getState) => {
  const uuid = v4()

  dispatch({
    type: 'FETCH_ELECTRICITY_KPI_PRICE_REQUEST',
    uuid,
  })

  const dateDebut = getStartDate(getState())
  const dateFin = getEndDate(getState())
  const perimetre = getCurrentVisibleElecPeesIds(getState()).join(';')
  const type = getTypeDonnee(getState()).join(';')
  const uorIdMst = user.uorIdMst(getState())
  const lanId = user.lanId(getState())

  return kpi
    .fetchKpi(
      uuid,
      dateDebut,
      dateFin,
      perimetre,
      'Prix',
      type,
      12,
      21,
      '',
      '',
      '',
      uorIdMst,
      lanId
    )
    .then(
      (response) => {
        if (response && response.headers.get('X-REQUEST-ID') === getKpiElecPriceUuid(getState())) {
          response.json().then((response) =>
            dispatch({
              type: 'FETCH_ELECTRICITY_KPI_PRICE_SUCCESS',
              response,
            })
          )
        }
      },
      (error) => {
        dispatch({
          type: 'FETCH_ELECTRICITY_KPI_PRICE_FAILURE',
          message: error.message || 'Something went wrong.',
        })
      }
    )
}

export const fetchGraph = (typesUpdated) => (dispatch, getState) => {
  const uuid = v4()

  const dateDebut = getStartDate(getState())
  const dateFin = getEndDate(getState())
  const perimetre = getCurrentVisibleElecPeesIds(getState())
  const type = 'FacturePrevu;Facture;FactureN1;Budget' // getTypeDonnee(getState()).join(';')
  const unite = getGraphUnite(getState())
  const agregation = getElecGraphAgregation(getState())
  const uorIdMst = user.uorIdMst(getState())
  const lanId = user.lanId(getState())

  if (typesUpdated && agregation === '2') return

  dispatch({
    type: 'FETCH_ELECTRICITY_GRAPH_REQUEST',
    uuid,
  })

  if (agregation === '2') {
    return graph
      .fetchTemporalGraph(
        uuid,
        dateDebut,
        dateFin,
        perimetre.join(';'),
        'Volume',
        type,
        '',
        unite,
        '',
        '',
        '',
        '',
        agregation,
        uorIdMst,
        lanId,
        false
      )
      .then(
        (response) => {
          if (response && response.headers.get('X-REQUEST-ID') === getElecGraphUuid(getState())) {
            response.json().then((response) =>
              dispatch({
                type: 'FETCH_ELECTRICITY_GRAPH_SUCCESS',
                response,
              })
            )
          }
        },
        (error) => {
          dispatch({
            type: 'FETCH_ELECTRICITY_GRAPH_FAILURE',
            message: error.message || 'Something went wrong.',
          })
        }
      )
  }

  const payload = {
    Type: agregation === '1' ? 'SIT' : 'CPT',
    Ids: perimetre,
    Debut: dateDebut,
    Fin: dateFin,
    Commodite: 'ELE',
    TypeDonnees: {
      FacturePrevu: true,
      Facture: true,
      FactureN1: true,
      Budget: true,
    },
  }

  return graph.fetchConsoGraph(uuid, payload).then(
    (response) => {
      if (response && response.headers.get('X-REQUEST-ID') === getElecGraphUuid(getState())) {
        response.json().then((response) =>
          dispatch({
            type: 'FETCH_ELECTRICITY_GRAPH_SUCCESS',
            response: { series: response },
          })
        )
      }
    },
    (error) => {
      dispatch({
        type: 'FETCH_ELECTRICITY_GRAPH_FAILURE',
        message: error.message || 'Something went wrong.',
      })
    }
  )
}

export const fetchGraphCumulative = () => (dispatch, getState) => {
  const uuid = v4()

  dispatch({
    type: 'FETCH_ELECTRICITY_GRAPH_CUMULATIVE_REQUEST',
    uuid,
  })

  const dateDebut = getStartDate(getState())
  const dateFin = getEndDate(getState())
  const perimetre = getCurrentVisibleElecPeesIds(getState()).join(';')
  const type = getTypeDonnee(getState()).join(';')
  const unite = getGraphUnite(getState())
  const uorIdMst = user.uorIdMst(getState())
  const lanId = user.lanId(getState())

  return graph
    .fetchNontemporalGraph(
      uuid,
      dateDebut,
      dateFin,
      perimetre,
      'VolumeHRS',
      type,
      '',
      unite,
      21,
      '',
      '',
      uorIdMst,
      lanId
    )
    .then(
      (response) => {
        if (
          response &&
          response.headers.get('X-REQUEST-ID') === getElecCumulativeUuid(getState())
        ) {
          response.json().then((response) =>
            dispatch({
              type: 'FETCH_ELECTRICITY_GRAPH_CUMULATIVE_SUCCESS',
              response,
            })
          )
        }
      },
      (error) => {
        dispatch({
          type: 'FETCH_ELECTRICITY_GRAPH_CUMULATIVE_FAILURE',
          message: error.message || 'Something went wrong.',
        })
      }
    )
}

export const fetchGraphCumulativeTemporal = () => (dispatch, getState) => {
  const uuid = v4()

  dispatch({
    type: 'FETCH_ELECTRICITY_GRAPH_CUMULATIVE_TEMPORAL_REQUEST',
    uuid,
  })

  const dateDebut = getStartDate(getState())
  const dateFin = getEndDate(getState())
  const perimetre = getCurrentVisibleElecPeesIds(getState()).join(';')
  const type = 'FacturePrevu;Facture;FactureN1;Budget' // getTypeDonnee(getState()).join(';')
  const unite = getGraphUnite(getState())
  const uorIdMst = user.uorIdMst(getState())
  const lanId = user.lanId(getState())

  return graph
    .fetchTemporalGraph(
      uuid,
      dateDebut,
      dateFin,
      perimetre,
      'VolumeHRS',
      type,
      '',
      unite,
      21,
      '',
      3,
      '',
      '',
      uorIdMst,
      lanId
    )
    .then(
      (response) => {
        if (
          response &&
          response.headers.get('X-REQUEST-ID') === getElecCumulativeTemporalUuid(getState())
        ) {
          response.json().then((response) =>
            dispatch({
              type: 'FETCH_ELECTRICITY_GRAPH_CUMULATIVE_TEMPORAL_SUCCESS',
              response,
            })
          )
        }
      },
      (error) => {
        dispatch({
          type: 'FETCH_ELECTRICITY_GRAPH_CUMULATIVE_TEMPORAL_FAILURE',
          message: error.message || 'Something went wrong.',
        })
      }
    )
}

export const updateElectricityGraphAgregation = (value) => (dispatch) => {
  dispatch({
    type: 'UPDATE_ELECTRICITY_GRAPH_AGREGATION',
    value,
  })
  dispatch(updateView())
}

export const setElecTooMuchPees = (value) => (dispatch) => {
  dispatch({
    type: 'SET_ELECTRICITY_TOO_MUCH_PEES',
    value,
  })

  if (value) {
    dispatch(updateElectricityGraphAgregation('2'))
  }
}
