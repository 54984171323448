import React, { useMemo, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import ReactTable from 'react-table'
import moment from 'moment'
import sum from 'lodash/sum'
import { getDate } from 'store/comex'
import { LoaderCenter, TableLoader } from 'components/common/Loader'
import { useDepenses } from './useDepenses'
import { useExposition } from './useExposition'
import { formatPercent, formatDecimal } from './format'
import CSVButton from '../common/CSVButton'
import mapValues from 'lodash/mapValues'
import { PercentCell } from './PercentCell'

const getStart = (m) =>
  m
    .clone()
    .startOf('year')
    .format('YYYY-MM-DD')

const getEnd = (m) =>
  m
    .clone()
    .endOf('year')
    .startOf('month')
    .format('YYYY-MM-DD')

export const Couverture = () => {
  const tableRef = useRef()
  const [type, setType] = useState('couverture') // 'couverture' | 'prix'

  const date = useSelector(getDate)
  const n = moment(date, 'YYYY-MM')

  const queryReel = useDepenses({
    type: 'actualise',
    start: getStart(n),
    end: getEnd(n),
  })

  const queryMarket = useExposition({
    type: 'actualise',
    start: getStart(n),
    end: getEnd(n),
  })

  const allPays = useMemo(() => [...new Set(queryMarket.data?.map((item) => item.MyPays))], [
    queryMarket.data,
  ])

  const columns = useMemo(() => {
    if (!queryMarket.data) return []
    return [
      {
        Header: () => (
          <div className="h-100 d-flex align-items-center">
            <label className="d-flex align-items-center m-0 mr-2">
              <input
                type="radio"
                checked={type === 'couverture'}
                onChange={() => setType('couverture')}
                className="mr-1"
              />
              <span>Couverture</span>
            </label>

            <label className="d-flex align-items-center m-0">
              <input
                type="radio"
                checked={type === 'prix'}
                onChange={() => setType('prix')}
                className="mr-1"
              />
              <span>Prix (€ / MWh)</span>
            </label>
          </div>
        ),
        accessor: 'title',
        width: 210,
        Cell: (props) => (
          <div
            css={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              fontSize: props.original.type === 'title' ? '1.1em' : '1em',
              color: props.original.type === 'title' ? 'var(--primary)' : 'inherit',
            }}
            title={`${props.value} (${props.original.description})`}
          >
            <span style={{ fontWeight: props.original.type === 'title' ? 'bold' : 'normal' }}>
              {props.value}
            </span>
            {'\u2000'}
            <small className="text-muted">{props.original.description}</small>
          </div>
        ),
      },
      ...allPays.map((pays, index) => ({
        Header: pays,
        title: pays,
        accessor: `${pays}.percent`,
        Cell: (props) => {
          const item = props.original[pays]
          switch (props.original.type) {
            case 'title':
              return null
            case 'price':
              return <div className="text-right">{formatDecimal(item?.price)}</div>
            default:
              if (type === 'couverture') return <PercentCell value={props.value} index={1} />
              return <div className="text-right">{formatDecimal(item?.price)}</div>
          }
        },
      })),
    ]
  }, [allPays, queryMarket.data, type])

  const data = useMemo(() => {
    if (!queryMarket.data | !queryReel.data) return []

    const couverture = queryMarket.data.reduce(
      (acc, item) => ({
        ...acc,
        [item.Quarter]: {
          ...acc[item.Quarter],
          title: item.Quarter.slice(-2),
          [item.MyPays]: {
            price: item.PrixFixe,
            percent: !item.VolumeTotal ? null : item.VolumeFixe / item.VolumeTotal,
          },
        },
      }),
      {}
    )

    const spot = queryMarket.data.reduce(
      (acc, item) => ({
        ...acc,
        [item.Quarter]: {
          ...acc[item.Quarter],
          title: item.Quarter.slice(-2),
          [item.MyPays]: {
            price: item.PrixFlottant,
            percent: !item.VolumeTotal ? null : item.VolumeFlottant / item.VolumeTotal,
          },
        },
      }),
      {}
    )

    const couvValues = Object.values(couverture)
    const couvYear = allPays.reduce((acc, pays) => {
      const prices = couvValues.map((item) => item[pays]?.price)
      const percents = couvValues.map((item) => item[pays]?.percent)
      return {
        ...acc,
        [pays]: {
          price: prices.some((price) => price != null) ? sum(prices) / couvValues.length : null,
          percent: percents.some((percent) => percent != null)
            ? sum(percents) / couvValues.length
            : null,
        },
      }
    }, {})

    const spotValues = Object.values(spot)
    const spotYear = allPays.reduce((acc, pays) => {
      const prices = spotValues.map((item) => item[pays]?.price)
      const percents = spotValues.map((item) => item[pays]?.percent)
      return {
        ...acc,
        [pays]: {
          price: prices.some((price) => price != null) ? sum(prices) / spotValues.length : null,
          percent: percents.some((percent) => percent != null)
            ? sum(percents) / spotValues.length
            : null,
        },
      }
    }, {})

    return [
      { title: 'Fixé', type: 'title' },
      ...Object.values(couverture),
      { title: 'Année', ...couvYear },
      { title: 'Flottant', type: 'title' },
      ...Object.values(spot),
      { title: 'Année', ...spotYear },
      {
        title: 'Prix annuel part énergie à date',
        type: 'price',
        ...mapValues(queryReel.data, (item) => ({ price: item.PrixTotal })),
      },
    ]
  }, [allPays, queryMarket.data, queryReel.data])

  if (queryMarket.isLoading) return <LoaderCenter />

  const createCSVContent = () => {
    const { data, columns } = tableRef.current.getResolvedState()
    const paysList = columns.slice(1).map((col) => col.title)
    const header1 = ['', ...paysList.flatMap((pays) => [pays, pays])]
    const header2 = ['', ...paysList.flatMap(() => ['%', '€/MWh'])]

    const body = data.map((item) => [
      item.title,
      ...paysList.flatMap((pays) => [
        formatPercent(item[pays]?.percent, 0, true),
        formatDecimal(item[pays]?.price, true),
      ]),
    ])

    return [header1, header2, ...body]
  }

  if (!data || data.length === 0) return null

  return (
    <div>
      <span css={{ position: 'absolute', top: 0, right: 0 }}>
        <CSVButton filename="Couverture.csv" createContent={createCSVContent} />
      </span>

      <ReactTable
        ref={tableRef}
        data={data}
        columns={columns}
        showPagination={false}
        loading={queryMarket.isFetching}
        LoadingComponent={TableLoader}
        minRows={0}
        pageSize={data.length}
        sortable={false}
        css={{
          '.rt-thead': {
            zIndex: 10,
          },
          '.rt-th': {
            padding: '8px 10px !important',
          },
          '.rt-td': {
            position: 'relative',
          },
          '.rt-th:nth-of-type(even), .rt-td:nth-of-type(even)': {
            backgroundColor: 'rgba(0,0,0,0.04)',
          },
        }}
      />
    </div>
  )
}
