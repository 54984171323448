const typeDonnee = (state = ['FacturePrevu', 'Facture', 'FactureN1', 'Budget'], action) => {
  switch (action.type) {
    case 'TOGGLE_TYPE_DONNEE':
      return state.indexOf(action.typeDonnee) === -1
        ? [...state, action.typeDonnee]
        : state.filter((typeDonnee) => typeDonnee !== action.typeDonnee)
    default:
      return state
  }
}

export default typeDonnee

export const getIsTypeDonneeChecked = (state, value) => state.typeDonnee.indexOf(value) !== -1
export const getTypeDonnee = (state) => state.typeDonnee
