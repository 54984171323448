import React from 'react'
import { useTranslation } from 'react-i18next'
import { Label, Button } from 'reactstrap'
import { useMutation, queryCache } from 'react-query'

import { request } from './request'
import Select from 'components/common/Select'
import { Input } from './Input'

const useAddPatrimonial = (siteId) => {
  return useMutation(
    ({ payload }) =>
      request({
        url: `sites/${siteId}/patrimonials`,
        method: 'POST',
        body: payload,
      }),
    {
      onSuccess: () => {
        queryCache.refetchQueries(['patrimonials', { id: siteId }])
      },
    }
  )
}

const useUpdatePatrimonial = (siteId) => {
  return useMutation(
    ({ patrimonialId, payload }) =>
      request({
        url: `sites/${siteId}/patrimonials/${patrimonialId}`,
        method: 'PUT',
        body: payload,
      }),
    {
      onSuccess: () => {
        queryCache.refetchQueries(['patrimonials', { id: siteId }])
      },
    }
  )
}

const useDeletePatrimonial = (siteId) => {
  return useMutation(
    ({ patrimonialId }) =>
      request(
        {
          url: `sites/${siteId}/patrimonials/${patrimonialId}`,
          method: 'DELETE',
        },
        false
      ),
    {
      onSuccess: () => {
        queryCache.refetchQueries(['patrimonials', { id: siteId }])
      },
    }
  )
}

const Patrimonial = ({ siteId, unite, initialValue, label, id, type }) => {
  const { t } = useTranslation()
  const [deletePatrimonial] = useDeletePatrimonial(siteId)
  const [updatePatrimonial] = useUpdatePatrimonial(siteId)
  const [value, setValue] = React.useState(initialValue)
  const isNumber = !isNaN(Number(initialValue))

  return (
    <Label>
      <div>{label}</div>

      <Input
        value={value}
        type={isNumber ? 'number' : 'text'}
        onChange={setValue}
        css={{ width: '100px !important' }}
      />

      <div className="ml-2" css={{ width: 50 }}>
        {unite}
      </div>

      <Button
        className="ml-3"
        color="primary"
        size="sm"
        disabled={value === initialValue}
        onClick={async () => {
          try {
            await updatePatrimonial({
              patrimonialId: id,
              payload: {
                Valeur: value,
                TypeIndicateur: type,
              },
            })
          } catch (error) {
            console.log(error)
          }
        }}
      >
        {t('site.form.validate')}
      </Button>

      <Button
        className="ml-2"
        size="sm"
        color="light"
        onClick={() => {
          deletePatrimonial({ patrimonialId: id })
        }}
      >
        {t('site.form.delete')}
      </Button>
    </Label>
  )
}

export const SitePatrimonials = ({ patrimonials, referentiel, siteId }) => {
  const { t } = useTranslation()

  const [addPatrimonial] = useAddPatrimonial(siteId)

  const [edit, setEdit] = React.useState(false)
  const [ref, setRef] = React.useState(Object.values(referentiel)[0].Id)
  const [input, setInput] = React.useState(0)

  const options = Object.values(referentiel).map((r) => ({ label: r.Nom, value: r.Id }))
  const value = options.find((o) => o.value === ref)

  return (
    <div
      css={{
        label: {
          '>div:first-of-type': {
            width: '170px !important',
          },
        },
      }}
    >
      {patrimonials.map((p) => (
        <Patrimonial
          key={p.Id}
          id={p.Id}
          siteId={siteId}
          unite={referentiel[p.TypeIndicateur].UniteNom}
          label={p.Propriete}
          initialValue={p.Valeur}
          type={p.TypeIndicateur}
        />
      ))}

      {edit && (
        <div className="d-flex align-items-start">
          <Label>
            <Select
              options={options}
              value={value}
              onChange={(option) => {
                setRef(option.value)
              }}
              isClearable={false}
              isSearchable={true}
              isMulti={false}
              placeholder=""
            />
            <Input
              type="number"
              value={input}
              onChange={(value) => {
                setInput(value)
              }}
              css={{ width: '100px !important' }}
            />
            <div className="ml-2" css={{ width: 50 }}>
              {referentiel[ref].UniteNom}
            </div>
          </Label>

          <Button
            className="ml-3"
            color="primary"
            size="sm"
            onClick={async () => {
              try {
                await addPatrimonial({
                  payload: {
                    Propriete: input,
                    Valeur: input,
                    TypeIndicateur: ref,
                  },
                })
                setEdit(false)
              } catch (error) {
                console.log(error)
              }
            }}
          >
            {t('site.form.validate')}
          </Button>

          <Button className="ml-2" color="light" size="sm" onClick={() => setEdit(false)}>
            {t('site.form.cancel')}
          </Button>
        </div>
      )}

      {!edit && (
        <div className="mt-4">
          <Button color="primary" size="sm" onClick={() => setEdit(true)}>
            {t('site.patrimonials.addKpi')}
          </Button>
        </div>
      )}
    </div>
  )
}
