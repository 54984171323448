import React, { useMemo, useRef } from 'react'
import { useSelector } from 'react-redux'
import ReactTable from 'react-table'
import moment from 'moment'
import { getDate } from 'store/comex'
import { useDepenses } from './useDepenses'
import { LoaderCenter, TableLoader } from 'components/common/Loader'
import mapValues from 'lodash/mapValues'
import { formatPercent, formatNoDecimal, formatDecimal } from './format'
import CSVButton from '../common/CSVButton'
import { PercentCell } from './PercentCell'
import sum from 'lodash/sum'
import sumBy from 'lodash/sumBy'

const getStart = (m) =>
  m
    .clone()
    .startOf('year')
    .format('YYYY-MM-DD')

const getEnd = (m) =>
  m
    .clone()
    .startOf('month')
    .format('YYYY-MM-DD')

const getMonthStart = (m) =>
  m
    .clone()
    .startOf('year')
    .format('MMMM')

const getMonthEnd = (m) =>
  m
    .clone()
    .endOf('month')
    .format('MMMM')

const divise = (a, b) => (b === 0 || b == null || a == null ? null : a / b)
const multiply = (a, b) => (b == null || a == null ? null : a * b)

export const YearToDate = () => {
  const tableRef = useRef()

  const date = useSelector(getDate)
  const year = Number(date.slice(0, 4))
  const n = moment(date, 'YYYY-MM')
  const n1 = n.clone().subtract(1, 'year')

  const queryN = useDepenses({ type: 'actualise', start: getStart(n), end: getEnd(n) })
  const queryN1 = useDepenses({ type: 'actualise', start: getStart(n1), end: getEnd(n1) })

  const columns = useMemo(() => {
    if (!queryN.data || !queryN1.data) return []
    return [
      {
        Header: '',
        accessor: 'title',
        width: 200,
        Cell: (props) => (
          <div
            css={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              fontSize: props.original.type === 'title' ? '1.1em' : '1em',
              color: props.original.type === 'title' ? 'var(--primary)' : 'inherit',
            }}
            title={`${props.value} (${props.original.description})`}
          >
            <span style={{ fontWeight: props.original.type === 'title' ? 'bold' : 'normal' }}>
              {props.value}
            </span>
            {'\u2000'}
            <small className="text-muted">{props.original.description}</small>
          </div>
        ),
      },
      ...Object.keys({ ...queryN.data, ...queryN1.data }).map((pays, index) => ({
        Header: pays,
        title: pays,
        accessor: pays,
        Cell: (props) => <ValueCell {...props} index={index} />,
      })),
      {
        Header: 'Total',
        accessor: 'total',
        Cell: (props) => <ValueCell {...props} index={null} />,
      },
    ]
  }, [queryN.data, queryN1.data])

  const data = useMemo(() => {
    if (!queryN.data || !queryN1.data) return []

    /* Année N */

    const consoN = mapValues(queryN.data, 'VolumeTotal')
    const totalConsoN = sum(Object.values(consoN))

    const depenseN = mapValues(queryN.data, (item) => item.DepenseTotale / 1000)
    const totalDepenseN = sum(Object.values(depenseN))

    const prixN = mapValues(queryN.data, 'PrixTotal')
    const totalPrixN = multiply(divise(totalDepenseN, totalConsoN), 1000)

    const energieN = mapValues(queryN.data, (item) => divise(item.DepenseNrj, item.DepenseTotale))
    const totalEnergieN = divise(
      sumBy(Object.values(queryN.data), 'DepenseNrj'),
      sumBy(Object.values(queryN.data), 'DepenseTotale')
    )

    const transportN = mapValues(queryN.data, (item) =>
      divise(item.DepenseTransport, item.DepenseTotale)
    )
    const totalTransportN = divise(
      sumBy(Object.values(queryN.data), 'DepenseTransport'),
      sumBy(Object.values(queryN.data), 'DepenseTotale')
    )

    const taxesN = mapValues(queryN.data, (item) => divise(item.DepenseTaxes, item.DepenseTotale))
    const totalTaxesN = divise(
      sumBy(Object.values(queryN.data), 'DepenseTaxes'),
      sumBy(Object.values(queryN.data), 'DepenseTotale')
    )

    /* Année N-1 */

    const consoN1 = mapValues(queryN1.data, 'VolumeTotal')
    const totalConsoN1 = sum(Object.values(consoN1))

    const depenseN1 = mapValues(queryN1.data, (item) => item.DepenseTotale / 1000)
    const totalDepenseN1 = sum(Object.values(depenseN1))

    const prixN1 = mapValues(queryN.data, 'PrixTotal')
    const totalPrixN1 = multiply(divise(totalDepenseN1, totalConsoN1), 1000)

    const energieN1 = mapValues(queryN1.data, (item) => divise(item.DepenseNrj, item.DepenseTotale))
    const totalEnergieN1 = divise(
      sumBy(Object.values(queryN1.data), 'DepenseNrj'),
      sumBy(Object.values(queryN1.data), 'DepenseTotale')
    )

    const transportN1 = mapValues(queryN1.data, (item) =>
      divise(item.DepenseTransport, item.DepenseTotale)
    )
    const totalTransportN1 = divise(
      sumBy(Object.values(queryN1.data), 'DepenseTransport'),
      sumBy(Object.values(queryN1.data), 'DepenseTotale')
    )

    const taxesN1 = mapValues(queryN1.data, (item) => divise(item.DepenseTaxes, item.DepenseTotale))
    const totalTaxesN1 = divise(
      sumBy(Object.values(queryN1.data), 'DepenseTaxes'),
      sumBy(Object.values(queryN1.data), 'DepenseTotale')
    )

    /* Ecarts et impacts */

    const ecartVolume = Object.entries(queryN1.data).reduce(
      (acc, [key, item]) => ({
        ...acc,
        [key]: queryN.data[key] != null ? queryN.data[key].VolumeTotal - item.VolumeTotal : null,
      }),
      {}
    )
    const totalEcartVolume = sum(Object.values(ecartVolume))

    const impactVolume = Object.entries(queryN1.data).reduce(
      (acc, [key, item]) => ({
        ...acc,
        [key]:
          queryN.data[key] != null
            ? (queryN.data[key].VolumeTotal - item.VolumeTotal) * item.PrixTotal
            : null,
      }),
      {}
    )
    const totalImpactVolume = sum(Object.values(impactVolume))

    const ecartDepense = Object.entries(queryN.data).reduce(
      (acc, [key, item]) => ({
        ...acc,
        [key]: queryN1.data[key] != null ? item.PrixTotal - queryN1.data[key].PrixTotal : null,
      }),
      {}
    )
    const totalEcartDepense = sum(Object.values(ecartDepense))

    const impactPrix = Object.entries(queryN.data).reduce(
      (acc, [key, item]) => ({
        ...acc,
        [key]:
          queryN1.data[key] != null
            ? item.PrixTotal - queryN1.data[key].PrixTotal - impactVolume[key]
            : null,
      }),
      {}
    )
    const totalImpactPrix = sum(Object.values(impactPrix))

    return [
      { title: `Année ${year}`, type: 'title' },
      {
        title: 'Consommation (MWh)',
        type: 'nodecimal',
        ...consoN,
        total: totalConsoN,
      },
      {
        title: 'Dépense (k€)',
        type: 'nodecimal',
        unite: 'k€',
        ...depenseN,
        total: totalDepenseN,
      },
      {
        title: 'Prix (€/MWh)',
        type: 'decimal',
        ...prixN,
        total: totalPrixN,
      },
      {
        title: '% énergie',
        type: 'percent',
        ...energieN,
        total: totalEnergieN,
      },
      {
        title: '% transport',
        type: 'percent',
        ...transportN,
        total: totalTransportN,
      },
      {
        title: '% taxes',
        type: 'percent',
        ...taxesN,
        total: totalTaxesN,
      },
      { title: `Année ${year - 1}`, type: 'title' },
      {
        title: 'Consommation (MWh)',
        type: 'nodecimal',
        ...consoN1,
        total: totalConsoN1,
      },
      {
        title: 'Dépense (k€)',
        type: 'nodecimal',
        unite: 'k€',
        ...depenseN1,
        total: totalDepenseN1,
      },
      {
        title: 'Prix (€/MWh)',
        type: 'decimal',
        ...prixN1,
        total: totalPrixN1,
      },
      {
        title: '% énergie',
        type: 'percent',
        ...energieN1,
        total: totalEnergieN1,
      },
      {
        title: '% transport',
        type: 'percent',
        ...transportN1,
        total: totalTransportN1,
      },
      {
        title: '% taxes',
        type: 'percent',
        ...taxesN1,
        total: totalTaxesN1,
      },
      { title: `${year} vs ${year - 1}`, type: 'title' },
      {
        title: `Ecart volume ${year}/${year - 1} (MWh)`,
        type: 'nodecimal',
        ...ecartVolume,
        total: totalEcartVolume,
      },
      {
        title: `Ecart dépense ${year}/${year - 1} (k€)`,
        type: 'decimal',
        unite: 'k€',
        ...ecartDepense,
        total: totalEcartDepense,
      },
      {
        title: 'Impact volume (k€)',
        type: 'nodecimal',
        unite: 'k€',
        ...impactVolume,
        total: totalImpactVolume,
      },
      {
        title: 'Impact prix (k€)',
        type: 'nodecimal',
        unite: 'k€',
        ...impactPrix,
        total: totalImpactPrix,
      },
    ]
  }, [queryN.data, queryN1.data, year])

  if (queryN.isLoading || queryN1.isLoading) return <LoaderCenter />

  const createCSVContent = () => {
    const { data, columns } = tableRef.current.getResolvedState()
    const paysList = columns.slice(1).map((col) => col.title)
    const header = ['', ...paysList]
    const body = data.map((item) => [
      item.title,
      ...paysList.map((pays) => {
        const value = item[pays]
        if (item.type === 'percent') return formatPercent(value, 0, true)
        if (item.type === 'nodecimal') return formatNoDecimal(value, true)
        if (item.type === 'decimal') return formatDecimal(value, true)
        return value
      }),
    ])
    return [header, ...body]
  }

  if (!data || data.length === 0) return null

  return (
    <div>
      <span css={{ position: 'absolute', top: 0, right: 0 }}>
        <CSVButton
          filename={`Cumul de ${getMonthStart(n)} à ${getMonthEnd(n)}.csv`}
          createContent={createCSVContent}
        />
      </span>

      <ReactTable
        ref={tableRef}
        data={data}
        columns={columns}
        sortable={false}
        showPagination={false}
        loading={queryN.isFetching || queryN1.isFetching}
        LoadingComponent={TableLoader}
        minRows={0}
        pageSize={data.length}
        css={{
          '.rt-thead': {
            zIndex: 10,
          },
          '.rt-th': {
            padding: '8px 10px !important',
          },
          '.rt-td': {
            position: 'relative',
          },
          '.rt-th:nth-of-type(even), .rt-td:nth-of-type(even)': {
            backgroundColor: 'rgba(0,0,0,0.04)',
          },
          '.rt-th:last-of-type, .rt-td:last-of-type': {
            backgroundColor: 'rgba(0,0,0,0.08)',
          },
        }}
      />
    </div>
  )
}

const ValueCell = (props) => {
  const index =
    ['% énergie', '% transport', '% taxes'].findIndex((title) => title === props.original.title) + 1

  switch (props.original.type) {
    case 'title':
      return null
    case 'decimal':
      return (
        <div className="text-right">
          {formatDecimal(props.value)}
          {props.value != null && props.original.unite && ` ${props.original.unite}`}
        </div>
      )
    case 'nodecimal':
      return (
        <div className="text-right">
          {formatNoDecimal(props.value)}
          {props.value != null && props.original.unite && ` ${props.original.unite}`}
        </div>
      )
    case 'percent':
      return <PercentCell value={props.value} index={index || null} />
    default:
      return props.value
  }
}
