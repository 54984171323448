import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import { Row, Col, CardGroup, Alert } from 'reactstrap'
import HighchartsReact from 'highcharts-react-official'
import { Highcharts } from 'helpers/highcharts'

import { initColumnChart } from '../../helpers/initCharts'
import {
  getKpiGasConso,
  getKpiGasExpense,
  getKpiGasPrice,
  getKpiGasConsoLoading,
  getKpiGasExpenseLoading,
  getKpiGasPriceLoading,
  getGasGraph,
  getGasGraphAgregation,
  getGasGraphTooMuchPees,
} from '../../reducers/powerConsumption/gas'
import { getCurrentVisibleGasPeesIds } from '../../reducers/currentSelection'
import * as actions from '../../actions/powerConsumption/gas'
import Kpi from '../kpi/Kpi'
import FormGraph from './FormGraph'
import ConsoTable from './ConsoTable'
import { getTypeDonnee } from '../../reducers/typeDonnee'

class Gas extends Component {
  componentDidMount() {
    this.fetchData()
  }

  fetchData() {
    const { fetchKpiConso, fetchKpiExpense, fetchKpiPrice, fetchGraph } = this.props
    fetchKpiConso()
    fetchKpiExpense()
    fetchKpiPrice()
    fetchGraph()
  }

  render() {
    const {
      type,
      currentVisibleGasPeesIds,
      kpiConso,
      kpiExpense,
      kpiPrice,
      kpiConsoLoading,
      kpiExpenseLoading,
      kpiPriceLoading,
      graph,
      agregation,
      tooMuchPees,
      updateGasGraphAgregation,
      t,
    } = this.props

    return (
      <div>
        {currentVisibleGasPeesIds.length ? (
          <div>
            <section className="pb-0">
              <Row>
                <Col xs="9">
                  <CardGroup>
                    <Kpi decimal={false} {...kpiConso} loading={kpiConsoLoading} />
                    <Kpi decimal={false} {...kpiExpense} loading={kpiExpenseLoading} />
                    <Kpi decimal={true} {...kpiPrice} loading={kpiPriceLoading} />
                  </CardGroup>
                </Col>
              </Row>
            </section>
            <section>
              <Row>
                <Col>
                  <Row>
                    <Col>
                      <h5 className="text-center">{t('powerConsumption.graphTitle')}</h5>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormGraph
                        agregation={agregation}
                        handleAgregationChange={updateGasGraphAgregation}
                        tooMuchPees={tooMuchPees}
                      />
                    </Col>
                  </Row>

                  <Row noGutters>
                    <Col>
                      <HighchartsReact
                        highcharts={Highcharts}
                        options={initColumnChart(graph, false, type)}
                        immutable={true}
                      />
                    </Col>
                  </Row>

                  <Row className="mt-4">
                    <Col>
                      <ConsoTable energy="GAZ" />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </section>
          </div>
        ) : (
          <section>
            <Alert color="warning" className="mb-0">
              {t('errors.noGasSelection')}
            </Alert>
          </section>
        )}
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  currentVisibleGasPeesIds: getCurrentVisibleGasPeesIds(state),
  kpiConso: getKpiGasConso(state),
  kpiExpense: getKpiGasExpense(state),
  kpiPrice: getKpiGasPrice(state),
  kpiConsoLoading: getKpiGasConsoLoading(state),
  kpiExpenseLoading: getKpiGasExpenseLoading(state),
  kpiPriceLoading: getKpiGasPriceLoading(state),
  graph: getGasGraph(state),
  agregation: getGasGraphAgregation(state),
  tooMuchPees: getGasGraphTooMuchPees(state),
  type: getTypeDonnee(state),
})

export default withTranslation()(connect(mapStateToProps, actions)(Gas))
