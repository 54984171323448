import React, { Component } from 'react'
import { Row, Col, Button } from 'reactstrap'
import { compose, withState } from 'recompose'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import Moment from 'moment'
import isEqual from 'lodash/isEqual'

// Config
import { marketCotationChart } from '../../../helpers/initCharts'

// Components
import QuotationBoard from './QuotationBoard'
import QuotationMarketPopover from './QuotationMarketPopover'
import PriceMonitoringGraph from './PriceMonitoringGraph'
import RangeInputDatepicker from '../../common/RangeInputDatepicker'

// Actions
import {
  fetchMyQuotations,
  fetchQuotationGraph,
  resetQuotationGraph,
  fetchMyAvailableQuotations,
} from '../../../actions/quotations'

// Reducers
import { getMarketQuotationGraph, getUserQuotationsWithOptions } from '../../../reducers/quotations'

class PriceMonitoring extends Component {
  constructor(props) {
    super(props)

    this.state = {
      productPopoverIsOpen: false,
    }

    this.chartRef = React.createRef()
  }

  componentDidMount() {
    this.props.fetchMyQuotations()
  }

  componentDidUpdate(oldProps) {
    if (oldProps.selectedQuotation !== this.props.selectedQuotation) {
      this.props.resetQuotationGraph(this.props.selectedQuotation.IdCotation)
    }

    if (!isEqual(oldProps.payload, this.props.payload)) {
      this.props.fetchQuotationGraph(this.props.payload)
    }

    if (
      (!this.props.selectedQuotation || this.props.selectedQuotation.length === 0) &&
      this.props.quotations &&
      this.props.quotations.length > 0
    ) {
      this.props.setSelectedQuotation([this.props.quotations[0]])
    }

    if (
      oldProps.quotations &&
      this.props.quotations &&
      oldProps.quotations.length !== this.props.quotations.length
    ) {
      this.props.fetchAvailableQuotations()

      const quotationIds = this.props.quotations.map((q) => q.Id)
      this.props.setSelectedQuotation(
        this.props.selectedQuotation.filter((q) => quotationIds.includes(q.Id))
      )
    }
  }
  setSelectedQuotation = (selectedQuotation) => {
    let newQuotation = [].concat(this.props.selectedQuotation)
    let exists = newQuotation.filter((quot) => quot.Id === selectedQuotation.Id)
    if (exists.length !== 0) {
      newQuotation = newQuotation.filter((quot) => quot.Id !== selectedQuotation.Id)
    } else {
      newQuotation.push(selectedQuotation)
    }
    this.props.setSelectedQuotation(newQuotation)
  }

  toggleProductPopover = () => {
    this.setState({
      productPopoverIsOpen: !this.state.productPopoverIsOpen,
    })
  }

  onDateMinChange = (date) => {
    const { dateMax, setDateMin } = this.props
    setDateMin(date)
    this.chartRef.current.chart.xAxis[0].setExtremes(
      Number(date.format('x')),
      Number(dateMax.format('x'))
    )
  }

  onDateMaxChange = (date) => {
    const { dateMin, setDateMax } = this.props
    setDateMax(date)
    this.chartRef.current.chart.xAxis[0].setExtremes(
      Number(dateMin.format('x')),
      Number(date.format('x'))
    )
  }

  onChartRender = (e) => {
    const { min, max } = e.target.xAxis[0]
    this.props.setDateMin(Moment(min))
    this.props.setDateMax(Moment(max))
  }

  render() {
    const config = marketCotationChart(this.props.quotationGraph)

    config.navigator = {
      series: {
        includeInCSVExport: false,
      },
    }

    if (config.chart) {
      config.chart.events = {
        render: this.onChartRender,
      }
    }

    return (
      <div className="price-monitoring">
        <QuotationMarketPopover
          isOpen={this.state.productPopoverIsOpen}
          togglePopover={this.toggleProductPopover}
        />
        <section>
          <Row>
            <Col className="text-right">
              <Button
                color="primary"
                className="rounded ml-3"
                onClick={this.toggleProductPopover}
                id="addProduct"
              >
                {this.props.t('market.alerts.addProduct')}
              </Button>
            </Col>
          </Row>
          <Row>
            <Col>
              <QuotationBoard
                setSelectedQuotation={this.setSelectedQuotation}
                selectedQuotation={this.props.selectedQuotation}
                quotations={this.props.quotations}
                isZoomOnContract={false}
                rowToDisplay={[
                  'price',
                  'currency',
                  'date',
                  'prevDay',
                  'MoyYtd',
                  'MinYtd',
                  'MaxYtd',
                ]}
                isEditable={true}
              />
            </Col>
          </Row>
        </section>
        {this.props.selectedQuotation && (
          <section>
            <Row>
              <Col>
                <Row>
                  <Col>
                    <h5>{this.props.t('market.priceMonitoring.graph')}</h5>
                  </Col>
                </Row>
                <Row>
                  <Col className="col-12">
                    <RangeInputDatepicker
                      min={this.props.dateMin}
                      max={this.props.dateMax}
                      onMinChange={this.onDateMinChange}
                      onMaxChange={this.onDateMaxChange}
                    />
                    <PriceMonitoringGraph config={config} ref={this.chartRef} />
                  </Col>
                </Row>
              </Col>
            </Row>
          </section>
        )}
      </div>
    )
  }
}

const mapStateToProps = (state, { selectedQuotation }) => ({
  quotationGraph: selectedQuotation
    ? getMarketQuotationGraph(state, selectedQuotation.IdCotation)
    : null,
  quotations: state.quotations.userQuotations || null,
  payload: getUserQuotationsWithOptions(selectedQuotation.map((q) => q.IdCotation))(state),
})

const mapDispatchToProps = (dispatch) => ({
  fetchMyQuotations: () => {
    dispatch(fetchMyQuotations())
  },
  fetchQuotationGraph: (payload) => {
    dispatch(fetchQuotationGraph(payload))
  },
  resetQuotationGraph: (quotationId) => {
    dispatch(resetQuotationGraph(quotationId))
  },
  fetchAvailableQuotations: () => {
    dispatch(fetchMyAvailableQuotations())
  },
})

export default compose(
  withTranslation(),
  withState('selectedQuotation', 'setSelectedQuotation', []),
  withState('dateMin', 'setDateMin', ''),
  withState('dateMax', 'setDateMax', ''),
  connect(mapStateToProps, mapDispatchToProps)
)(PriceMonitoring)
