import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import ReactTable from 'react-table'
import { useTranslation } from 'react-i18next'
import matchSorter from 'match-sorter'
import range from 'lodash/range'
import uniqBy from 'lodash/uniqBy'
import { apiConf } from 'commons'
import { Button } from 'reactstrap'
import Notifications from 'react-notification-system-redux'
import moment from 'moment'
import { RiDownload2Line } from 'react-icons/ri'

import { ComboboxFilter } from './ComboboxFilter'
import { translations } from 'helpers/react-table'
import { getCurrentVisiblePeesIds } from 'reducers/currentSelection'

const getPageSizeOptions = (total) =>
  [10, 15].concat(
    range(7)
      .map((i) => i * 5 + 20)
      .filter((n) => n <= total)
  )

const EyeCell = () => (
  <div
    css={{
      textAlign: 'center',
      opacity: 0.7,
      '&:hover': { opacity: 1 },
    }}
  >
    <i className="icon-eye" />
  </div>
)

export const SitesTable = ({ sites, toggleModal }) => {
  const perimeter = useSelector(getCurrentVisiblePeesIds)
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const ref = React.useRef(null)

  const columns = React.useMemo(
    () =>
      [
        {
          Header: t('sites.name'),
          accessor: 'name',
        },
        {
          Header: t('sites.raisonSociale'),
          accessor: 'raisonSoc',
        },
        {
          Header: t('sites.formeJuridique'),
          accessor: 'formeJuri',
        },
        {
          Header: t('sites.siren'),
          accessor: 'siren',
        },
        {
          Header: t('sites.owner'),
          accessor: 'owner',
        },
        {
          Header: t('sites.address'),
          accessor: 'address',
        },
        {
          Header: t('sites.postalCode'),
          accessor: 'postalCode',
        },
        {
          Header: t('sites.city'),
          accessor: 'city',
        },
        {
          Header: t('sites.country'),
          accessor: 'country',
        },
        {
          Header: t('sites.siteCode'),
          accessor: 'siteCode',
        },
        {
          Header: t('sites.surface'),
          accessor: 'surface',
        },
        {
          Header: t('sites.typology'),
          accessor: 'typology',
        },
        {
          accessor: 'id',
          filterable: false,
          width: 40,
          Cell: EyeCell,
        },
      ].map((column) =>
        column.filterable === false
          ? column
          : {
              ...column,
              filterAll: true,
              filterMethod: (filter, rows) =>
                matchSorter(rows, filter.value, {
                  keys: [filter.id],
                  threshold: matchSorter.rankings.CONTAINS,
                }),
              Filter: ({ onChange, column }) => {
                const data =
                  ref !== null && ref.current !== null
                    ? ref.current.getResolvedState().sortedData
                    : []

                const items = uniqBy(data, column.id).map((item) => item[column.id])

                return <ComboboxFilter items={items} onChange={onChange} />
              },
            }
      ),
    [t]
  )

  const data = React.useMemo(
    () =>
      Object.values(sites).map((site) => ({
        id: site.id,
        name: site.SphNom,
        raisonSoc: (site.PersonneMorale && site.PersonneMorale.RaisonSociale) || '-',
        formeJuri: (site.PersonneMorale && site.PersonneMorale.FormeJuridique) || '-',
        owner: (site.Proprietaire && site.Proprietaire.Nom + ' ' + site.Proprietaire.Prenom) || '-',
        siren: (site.PersonneMorale && site.PersonneMorale.Siren) || '-',
        address: site.SphAdresse.AdrAdresse || '-',
        postalCode: site.SphAdresse.AdrCodePostal || '-',
        city: site.SphAdresse.AdrVille || '-',
        country: site.SphAdresse.AdrPays || '-',
        siteCode: site.SitClef || '-',
        surface: site.SurfaceTotale || '-',
        typology: (site.Organisation && site.Organisation.PathBu) || '-',
      })),
    [sites]
  )

  const getCsvFile = React.useCallback(
    async (url, filePrefix) => {
      try {
        const res = await apiConf.superFetch({
          url,
          method: 'POST',
          body: perimeter,
        })

        if (res.status < 200 || res.status >= 300) throw new Error(res.status)

        const blob = await res.blob()
        const a = document.createElement('a')
        a.href = URL.createObjectURL(blob)
        a.download = `${filePrefix}_${moment().format('YYYYMMDDHHmm')}.csv`
        a.style.display = 'text/plain'
        document.body.appendChild(a)
        a.click()
        a.remove()
      } catch (error) {
        dispatch(
          Notifications.error({
            title: 'Something went wrong !',
            position: 'bl',
            autoDismiss: 4,
          })
        )
      }
    },
    [dispatch, perimeter]
  )

  return (
    <>
      <div
        css={{
          display: 'flex',
          justifyContent: 'flex-end',
          marginBottom: '1rem',
          button: {
            marginLeft: '1rem',
            svg: {
              transform: 'scale(1.4) translateY(-1px)',
              marginRight: '0.8rem',
            },
          },
        }}
      >
        <Button
          color="primary"
          onClick={() => {
            getCsvFile('sites/filter/export/csv', 'SiteExtract')
          }}
        >
          <RiDownload2Line />
          Export sites
        </Button>

        <Button
          color="primary"
          onClick={() => {
            getCsvFile('pees/filter/export/csv', 'PeeExtract')
          }}
        >
          <RiDownload2Line />
          Export compteurs
        </Button>
      </div>

      <ReactTable
        ref={ref}
        data={data}
        columns={columns}
        defaultPageSize={15}
        pageSizeOptions={getPageSizeOptions(data.length)}
        getTrProps={(_, rowInfo) => ({
          onClick: rowInfo ? toggleModal(rowInfo.original.id) : undefined,
          style: { cursor: rowInfo ? 'pointer' : 'unset' },
        })}
        {...translations(t)}
        className="shadow-sm -striped -highlight"
        css={{
          fontSize: 13,
          '.rt-thead.-filters .rt-th': {
            overflow: 'unset',
          },
        }}
        filterable
      />
    </>
  )
}
