import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { withTranslation } from 'react-i18next'

class HeaderTitle extends Component {
  render() {
    const { match, t } = this.props
    const titles = {
      login: t('header.titles.login'),
      'power-consumption': t('header.titles.power-consumption'),
      'load-curve': t('header.titles.load-curve'),
      expenses: t('header.titles.expenses'),
      bills: t('header.titles.bills'),
      contracts: t('header.titles.contracts'),
      'market-informations': t('header.titles.market-informations'),
      'market-admin': t('header.titles.market-admin'),
      sites: t('header.titles.sites'),
      error: t('header.titles.error'),
    }
    let title = ''

    if (match.params.path === undefined) {
      title = titles['login']
    } else if (!titles.hasOwnProperty(match.params.path)) {
      title = titles['error']
    } else {
      title = titles[match.params.path]
    }

    return <h1>{title}</h1>
  }
}

HeaderTitle.propTypes = {
  match: PropTypes.object.isRequired,
}

HeaderTitle = withRouter(HeaderTitle)

export default withTranslation()(HeaderTitle)
