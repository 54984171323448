import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import { withRouter } from 'react-router-dom'
import { Navbar, Nav, NavItem, Button } from 'reactstrap'

import * as userCommons from 'commons'
import { Options } from 'components/options/Options'
import { getUor } from 'store/selectors'

// Configuration
import * as env from '../../helpers/env'

import TopNavLink from './TopNavLink'

class TopNav extends Component {
  constructor(props) {
    super(props)
    this.isConsumptionActive = this.isConsumptionActive.bind(this)
    this.isExpensesActive = this.isExpensesActive.bind(this)
    this.isContractsActive = this.isContractsActive.bind(this)
  }

  isBasicAllowed() {
    if (this.props.user && this.props.user.profile && this.props.user.profile.role) {
      if (Array.isArray(this.props.user.profile.role)) {
        return this.props.user.profile.role.some((role) => env.basicRoles.includes(role))
      } else {
        return env.basicRoles.includes(this.props.user.profile.role)
      }
    }

    return false
  }

  isSitesAllowed() {
    if (this.props.user && this.props.user.profile && this.props.user.profile.role) {
      if (Array.isArray(this.props.user.profile.role)) {
        return this.props.user.profile.role.some((role) => env.otherRoles.includes(role))
      } else {
        return env.otherRoles.includes(this.props.user.profile.role)
      }
    }

    return false
  }

  isConsumptionActive() {
    const { location } = this.props
    return location && location.pathname.split('/')[1] === 'power-consumption'
  }

  isExpensesActive() {
    const { location } = this.props
    return location && location.pathname.split('/')[1] === 'expenses'
  }

  isContractsActive() {
    const { location } = this.props
    return location && location.pathname.split('/')[1] === 'contracts'
  }

  render() {
    const { isAdmin, setUserPanelOpen, userPanelOpen, t, uor } = this.props

    let paths = []

    if (this.isBasicAllowed()) {
      paths.push({
        path: '/power-consumption/all-amenities',
        text: t('topNav.consumption'),
        active: this.isConsumptionActive,
      })
      paths.push({
        path: '/expenses/all-amenities',
        text: t('topNav.expenses'),
        active: this.isExpensesActive,
      })
      paths.push({
        path: '/bills',
        text: t('topNav.bills'),
      })
      paths.push({
        path: '/contracts/energy-meter',
        text: t('topNav.contracts'),
        active: this.isContractsActive,
      })
      paths.push({
        path: '/market-informations',
        text: t('topNav.market'),
      })

      paths.push({
        path: '/market-admin',
        text: t('topNav.marketAdmin'),
        requireAdmin: true,
      })
    }

    if (this.isSitesAllowed()) {
      paths.push({
        path: '/sites',
        text: t('topNav.sites'),
      })
    }

    if (uor === '620') {
      paths.push({
        path: '/comex',
        text: 'COMEX',
      })
    }

    return (
      <Navbar color="secondary" dark expand className="topNav">
        <Nav navbar className="flex-row">
          {paths.map((i, index) => {
            if ((i.requireAdmin && isAdmin) || !i.requireAdmin) {
              return (
                <NavItem key={index}>
                  <TopNavLink path={i.path} isActive={i.active}>
                    {i.text}
                    {i.active ? <div className="active-nav" /> : null}
                  </TopNavLink>
                </NavItem>
              )
            }
            return undefined
          })}
        </Nav>
        <Nav className="ml-auto" navbar>
          <Options />
          <NavItem>
            <Button
              color="link"
              onClick={() => setUserPanelOpen(!userPanelOpen)}
              title={t('topNav.openuser')}
            >
              <i className="icon-user" />
              <i className="icon-smallarrow-down" />
            </Button>
          </NavItem>
        </Nav>
      </Navbar>
    )
  }
}

const mapStateToProps = (state) => ({
  isAdmin: userCommons.isAdmin(state),
  user: state.oidc && state.oidc.user,
  uor: getUor(state),
})

TopNav.propTypes = {
  location: PropTypes.object.isRequired,
  setUserPanelOpen: PropTypes.func.isRequired,
  userPanelOpen: PropTypes.bool.isRequired,
  isAdmin: PropTypes.bool.isRequired,
  uor: PropTypes.string.isRequired,
}

export default withTranslation()(withRouter(connect(mapStateToProps)(TopNav)))
