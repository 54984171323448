import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { Route, withRouter } from 'react-router-dom'
import { withTranslation } from 'react-i18next'
import { Row, Col } from 'reactstrap'

import {
  pathsWithConsumptionParams,
  pathsWithExpensesParams,
  pathsWithDateRange,
} from '../../paths'
import PowerConsumptionNav from '../powerConsumption/PowerConsumptionNav'
import ExpensesNav from '../expenses/ExpensesNav'
import ContractsNav from '../contracts/ContractsNav'
import HeaderTitle from './HeaderTitle'
import DateRange from './DateRange'
import CsvDownload from './CsvDownload'
import FormLoadCurve from '../powerConsumption/FormLoadCurve'
import FormTypeDonnee from '../typeDonnee/FormTypeDonnee'

class Header extends Component {
  render() {
    const { location, t } = this.props

    const typeDonneesDepenses = {
      FacturePrevu: t('header.types.facturePrevu'),
      Facture: t('header.types.facture'),
      FactureN1: t('header.types.factureN1'),
      Budget: t('header.types.budget'),
    }

    const typeDonneesConsommation = {
      FacturePrevu: t('header.types.facturePrevu'),
      Facture: t('header.types.facture'),
      FactureN1: t('header.types.factureN1'),
      Budget: t('header.types.budget'),
    }

    const typeDonneesLoadCurve = {
      Mesure: t('header.types.mesure'),
      MesureN1: t('header.types.mesureN1'),
      MesureS1: t('header.types.mesureS1'),
      Normalise: t('header.types.normalise'),
      PS: t('header.types.ps'),
      DepassementsPS: t('header.types.depassementPS'),
      Talon: t('header.types.talonPuissance'),
    }

    return (
      <Col tag="header">
        <Row>
          <Col>
            <HeaderTitle />
          </Col>
        </Row>
        {(pathsWithDateRange.includes(location.pathname) ||
          location.pathname.startsWith('/contracts/')) &&
          !location.pathname.startsWith('/bills') && (
            <Row>
              <Col>
                <DateRange />
              </Col>
            </Row>
          )}
        {pathsWithDateRange.includes(location.pathname) && location.pathname.startsWith('/bills') && (
          <Row>
            <Col>
              <DateRange />
            </Col>
            <Col>
              <CsvDownload />
            </Col>
          </Row>
        )}
        {pathsWithExpensesParams.includes(location.pathname) && (
          <Row>
            <Col>
              <FormTypeDonnee typeDonnees={typeDonneesDepenses} />
            </Col>
          </Row>
        )}
        {pathsWithConsumptionParams.includes(location.pathname) && (
          <Row>
            <Col>
              <FormTypeDonnee typeDonnees={typeDonneesConsommation} />
            </Col>
          </Row>
        )}
        {location.pathname === '/power-consumption/load-curve' && (
          <Row>
            <Col>
              <FormLoadCurve typeDonnees={typeDonneesLoadCurve} />
            </Col>
          </Row>
        )}
        <Route path="/power-consumption/*" component={PowerConsumptionNav} />
        <Route path="/expenses/*" component={ExpensesNav} />
        <Route path="/contracts" component={ContractsNav} />
      </Col>
    )
  }
}

Header.propTypes = {
  location: PropTypes.object.isRequired,
}

export default withTranslation()(withRouter(Header))
