import moment from 'moment'

const initialState = {
  period: 'DATE_RANGE_LAST_12_MONTHS',
  startDate: moment()
    .subtract(12, 'months')
    .startOf('month')
    .format('YYYY-MM-DD'),
  endDate: moment()
    .subtract(1, 'months')
    .endOf('month')
    .format('YYYY-MM-DD'),
}

const dateRange = (state = initialState, action) => {
  switch (action.type) {
    case 'DATE_RANGE_LAST_MONTH':
      return {
        period: 'DATE_RANGE_LAST_MONTH',
        startDate: moment()
          .subtract(1, 'months')
          .startOf('month')
          .format('YYYY-MM-DD'),
        endDate: moment()
          .subtract(1, 'months')
          .endOf('month')
          .format('YYYY-MM-DD'),
      }
    case 'DATE_RANGE_LAST_3_MONTHS':
      return {
        period: 'DATE_RANGE_LAST_3_MONTHS',
        startDate: moment()
          .subtract(3, 'months')
          .startOf('month')
          .format('YYYY-MM-DD'),
        endDate: moment()
          .subtract(1, 'months')
          .endOf('month')
          .format('YYYY-MM-DD'),
      }
    case 'DATE_RANGE_LAST_6_MONTHS':
      return {
        period: 'DATE_RANGE_LAST_6_MONTHS',
        startDate: moment()
          .subtract(6, 'months')
          .startOf('month')
          .format('YYYY-MM-DD'),
        endDate: moment()
          .subtract(1, 'months')
          .endOf('month')
          .format('YYYY-MM-DD'),
      }
    case 'DATE_RANGE_LAST_12_MONTHS':
      return initialState
    case 'DATE_RANGE_THIS_YEAR':
      return {
        period: 'DATE_RANGE_THIS_YEAR',
        startDate: moment()
          .startOf('year')
          .format('YYYY-MM-DD'),
        endDate: moment().format('YYYY-MM-DD'),
      }
    case 'DATE_RANGE_LAST_YEAR':
      return {
        period: 'DATE_RANGE_LAST_YEAR',
        startDate: moment()
          .subtract(1, 'year')
          .startOf('year')
          .format('YYYY-MM-DD'),
        endDate: moment()
          .subtract(1, 'year')
          .endOf('year')
          .format('YYYY-MM-DD'),
      }
    case 'DATE_RANGE_CUSTOM':
      return { ...state, period: 'DATE_RANGE_CUSTOM' }
    case 'DATE_RANGE_UPDATE_START_DATE':
      return action.date.isSameOrAfter(state.endDate)
        ? state
        : { ...state, period: 'DATE_RANGE_CUSTOM', startDate: action.date.format('YYYY-MM-DD') }
    case 'DATE_RANGE_UPDATE_END_DATE':
      return action.date.isSameOrBefore(state.startDate)
        ? state
        : { ...state, period: 'DATE_RANGE_CUSTOM', endDate: action.date.format('YYYY-MM-DD') }
    default:
      return state
  }
}

export default dateRange

export const getDateRange = (state) => state.dateRange
export const getStartDate = (state) => (state.dateRange && state.dateRange.startDate) || '2017-1-1'
export const getEndDate = (state) => (state.dateRange && state.dateRange.endDate) || '2017-1-1'
