import Oidc from 'oidc-client'
import { createUserManager } from 'redux-oidc'
import * as env from '../helpers/env'

let userManager

env.onReady(() => {
  // Paramètres de log du module OIDC
  Oidc.Log.logger = console
  Oidc.Log.level = Oidc.Log[env.OidcLogLevel]

  // Configuration OIDC
  const userManagerConfig = {
    client_id: env.clientId,
    automaticSilentRenew: true,
    silent_redirect_uri: process.env.NODE_ENV ? undefined : env.silentRedirectUri,
    redirect_uri: env.postLoginRedirectUrl,
    // response_type: 'id_token token',
    response_type: env.reponseType,
    scope: env.scopes,
    authority: env.autorityUrl,
    filterProtocolClaims: true,
    loadUserInfo: true,
    silentRequestTimeout: process.env.NODE_ENV ? 360_000 : undefined,
  }

  userManager = createUserManager(userManagerConfig)

  // Same mechanism for when the automatic renewal of a token fails
  userManager.events.addSilentRenewError(function(error) {
    console.error('Error renewing token', error)
  })

  // When a user logs in successfully or a token is renewed, the `userLoaded`
  // event is fired. the `addUserLoaded` method allows to register a callback to
  // that event
  userManager.events.addUserLoaded(function(loadedUser) {
    // Make some controls after success login

    // Si pas de role alors on cree une structure vide
    if (loadedUser.profile) {
      let roles = []
      switch (typeof loadedUser.profile.role) {
        case undefined:
          roles = []
          break
        case 'string':
          roles = [loadedUser.profile.role]
          break
        default:
          if (Array.isArray(loadedUser.profile.role)) roles = loadedUser.profile.role
          break
      }
      loadedUser.profile.role = roles
    }
  })

  // When the automatic session management feature detects a change in
  // the user session state, the `userSignedOut` event is fired.
  userManager.events.addUserSignedOut(function() {})
})

export { userManager as default }
