import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import { Row, Col, Button } from 'reactstrap'

import CurrentSelection from './CurrentSelection'
import CurrentSelectionFooter from './CurrentSelectionFooter'
import { getSearchPanelOpen } from '../../../reducers/searchPanel'
import { getCurrentPeesIds } from '../../../reducers/currentSelection'
import { toggleSearchPanel } from '../../../actions/searchPanel'
import { fetchOrganisation } from '../../../actions/organisation'
import { updateView } from '../../../actions/views'

function PanelButton({ dispatch, searchPanelOpen, toggleSearchPanel, isEmptySelection, t }) {
  return (
    <Button
      className="w-100 toggle-panel glow"
      color="secondary"
      onClick={() => {
        dispatch(toggleSearchPanel)
        if (searchPanelOpen) dispatch(updateView())
      }}
      active={searchPanelOpen}
    >
      {searchPanelOpen ? t('sidebar.panel.close') : t('sidebar.panel.open')}
    </Button>
  )
}
const mapStateToProps = (state) => ({
  searchPanelOpen: getSearchPanelOpen(state),
  isEmptySelection: getCurrentPeesIds(state).length === 0,
  toggleSearchPanel: toggleSearchPanel(),
})

PanelButton.propTypes = {
  dispatch: PropTypes.func.isRequired,
  searchPanelOpen: PropTypes.bool.isRequired,
  isEmptySelection: PropTypes.bool.isRequired,
  toggleSearchPanel: PropTypes.func.isRequired,
}
const PanelButtonComponent = withTranslation()(connect(mapStateToProps)(PanelButton))

class SidebarSelectionCurrent extends Component {
  componentDidMount() {
    this.fetchData()
  }

  fetchData() {
    const { dispatch } = this.props
    dispatch(fetchOrganisation())
  }

  render() {
    return (
      <div className="current-selection-wrapper">
        <Row className="current-selection-header">
          <Col>
            <PanelButtonComponent />
          </Col>
        </Row>
        <Row className="current-selection-content">
          <Col>
            <CurrentSelection />
          </Col>
        </Row>
        <Row className="current-selection-footer">
          <CurrentSelectionFooter />
        </Row>
      </div>
    )
  }
}

export default connect()(SidebarSelectionCurrent)
