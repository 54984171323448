import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import { Row, Col, CardGroup, Alert } from 'reactstrap'
import HighchartsReact from 'highcharts-react-official'
import { Highcharts } from 'helpers/highcharts'

import { initColChart, initPieChart } from '../../helpers/initCharts'
import {
  getKpiExpTotal,
  getKpiExpEnergy,
  getKpiExpTransport,
  getKpiExpTaxes,
  getKpiExpAlt,
  getKpiPriceTotal,
  getKpiPriceEnergy,
  getKpiPriceTransport,
  getKpiPriceTaxes,
  getKpiPriceAlt,
  getKpiExpTotalLoading,
  getKpiExpEnergyLoading,
  getKpiExpTransportLoading,
  getKpiExpTaxesLoading,
  getKpiExpAltLoading,
  getKpiPriceTotalLoading,
  getKpiPriceEnergyLoading,
  getKpiPriceTransportLoading,
  getKpiPriceTaxesLoading,
  getKpiPriceAltLoading,
  getPieGraph,
  getColGraph,
} from '../../reducers/expenses/gas'
import { getCurrentVisibleGasPeesIds } from '../../reducers/currentSelection'
import * as actions from '../../actions/expenses/gas'
import Kpi from '../kpi/Kpi'
import FormDecomposition from './FormDecomposition'
import ExpenseTable from './ExpenseTable'
import { Devise } from 'components/options/Devise'

class Gas extends Component {
  componentDidMount() {
    this.fetchData()
  }

  // shouldComponentUpdate(nextProps) {
  //   return (
  //     JSON.stringify([nextProps.currentVisiblePeesIds, nextProps.colGraph, nextProps.pieGraph]) !==
  //     JSON.stringify([this.props.currentVisiblePeesIds, this.props.colGraph, this.props.pieGraph])
  //   )
  // }

  fetchData() {
    const {
      currentVisiblePeesIds,
      fetchKpiExpTotal,
      fetchKpiExpEnergy,
      fetchKpiExpTransport,
      fetchKpiExpTaxes,
      fetchKpiExpAlt,
      fetchKpiPriceTotal,
      fetchKpiPriceEnergy,
      fetchKpiPriceTransport,
      fetchKpiPriceTaxes,
      fetchKpiPriceAlt,
      fetchPieGraph,
      fetchColGraph,
    } = this.props

    if (currentVisiblePeesIds.length) {
      fetchKpiExpTotal()
      fetchKpiExpEnergy()
      fetchKpiExpTransport()
      fetchKpiExpTaxes()
      fetchKpiExpAlt()
      fetchKpiPriceTotal()
      fetchKpiPriceEnergy()
      fetchKpiPriceTransport()
      fetchKpiPriceTaxes()
      fetchKpiPriceAlt()
      fetchPieGraph()
      fetchColGraph()
    }
  }

  toggleCategory = (categories) => {
    this.colChartRef.current.chart.series
      .filter((s) => categories.some((c) => s.name.includes(c)))
      .forEach((s) => s.setVisible(!s.visible))

    this.pieChartRef.current.chart.series[0].data
      .filter((s) => categories.some((c) => s.name.includes(c)))
      .forEach((s) => s.setVisible(!s.visible))
  }

  toggleEnergy = () => this.toggleCategory(['Energy', 'Energie'])
  toggleGrid = () => this.toggleCategory(['Grid', 'Transport'])
  toggleTaxes = () => this.toggleCategory(['Taxes'])
  toggleOthers = () => this.toggleCategory(['Other', 'Autre'])

  render() {
    const {
      currentVisiblePeesIds,
      kpiExpTotal,
      kpiExpEnergy,
      kpiExpTransport,
      kpiExpTaxes,
      kpiExpAlt,
      kpiPriceTotal,
      kpiPriceEnergy,
      kpiPriceTransport,
      kpiPriceTaxes,
      kpiPriceAlt,
      kpiExpTotalLoading,
      kpiExpEnergyLoading,
      kpiExpTransportLoading,
      kpiExpTaxesLoading,
      kpiExpAltLoading,
      kpiPriceTotalLoading,
      kpiPriceEnergyLoading,
      kpiPriceTransportLoading,
      kpiPriceTaxesLoading,
      kpiPriceAltLoading,
      pieGraph,
      colGraph,
      t,
    } = this.props

    this.colChartRef = React.createRef()
    this.pieChartRef = React.createRef()

    return (
      <div>
        {currentVisiblePeesIds.length ? (
          <div>
            <section className="pb-0">
              <Row>
                <Col>
                  <h5>{t('expenses.labels.expenses')}</h5>
                </Col>
              </Row>
              <Row>
                <Col>
                  <CardGroup>
                    <Kpi decimal={false} {...kpiExpTotal} loading={kpiExpTotalLoading} />
                    <Kpi decimal={false} {...kpiExpEnergy} loading={kpiExpEnergyLoading} />
                    <Kpi decimal={false} {...kpiExpTransport} loading={kpiExpTransportLoading} />
                    <Kpi decimal={false} {...kpiExpTaxes} loading={kpiExpTaxesLoading} />
                    <Kpi decimal={false} {...kpiExpAlt} loading={kpiExpAltLoading} />
                  </CardGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <h5>{t('expenses.labels.price')}</h5>
                </Col>
              </Row>
              <Row>
                <Col>
                  <CardGroup>
                    <Kpi decimal={true} {...kpiPriceTotal} loading={kpiPriceTotalLoading} />
                    <Kpi decimal={true} {...kpiPriceEnergy} loading={kpiPriceEnergyLoading} />
                    <Kpi decimal={true} {...kpiPriceTransport} loading={kpiPriceTransportLoading} />
                    <Kpi decimal={true} {...kpiPriceTaxes} loading={kpiPriceTaxesLoading} />
                    <Kpi decimal={true} {...kpiPriceAlt} loading={kpiPriceAltLoading} />
                  </CardGroup>
                </Col>
              </Row>
            </section>
            <section>
              <Row noGutters>
                <Col xs="10">
                  <h5 className="text-center">
                    {t('expenses.labels.graphTitle')} (<Devise />)
                  </h5>
                </Col>
                <Col xs="2">
                  <h5 className="text-center">{t('expenses.labels.pieTitle')}</h5>
                </Col>
              </Row>
              <Row noGutters>
                <Col xs="10">
                  <HighchartsReact
                    highcharts={Highcharts}
                    options={initColChart(colGraph)}
                    ref={this.colChartRef}
                    immutable={true}
                  />
                  <FormDecomposition
                    toggleEnergy={this.toggleEnergy}
                    toggleGrid={this.toggleGrid}
                    toggleTaxes={this.toggleTaxes}
                    toggleOthers={this.toggleOthers}
                  />
                </Col>
                <Col xs="2">
                  <HighchartsReact
                    highcharts={Highcharts}
                    options={initPieChart(pieGraph)}
                    ref={this.pieChartRef}
                    immutable={true}
                  />
                </Col>
              </Row>

              <Row className="mt-4">
                <Col>
                  <ExpenseTable energy="GAZ" />
                </Col>
              </Row>
            </section>
          </div>
        ) : (
          <Alert color="warning" className="mb-0">
            {t('errors.noGasSelection')}
          </Alert>
        )}
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  currentVisiblePeesIds: getCurrentVisibleGasPeesIds(state),
  kpiExpTotal: getKpiExpTotal(state),
  kpiExpEnergy: getKpiExpEnergy(state),
  kpiExpTransport: getKpiExpTransport(state),
  kpiExpTaxes: getKpiExpTaxes(state),
  kpiExpAlt: getKpiExpAlt(state),
  kpiPriceTotal: getKpiPriceTotal(state),
  kpiPriceEnergy: getKpiPriceEnergy(state),
  kpiPriceTransport: getKpiPriceTransport(state),
  kpiPriceTaxes: getKpiPriceTaxes(state),
  kpiPriceAlt: getKpiPriceAlt(state),
  kpiExpTotalLoading: getKpiExpTotalLoading(state),
  kpiExpEnergyLoading: getKpiExpEnergyLoading(state),
  kpiExpTransportLoading: getKpiExpTransportLoading(state),
  kpiExpTaxesLoading: getKpiExpTaxesLoading(state),
  kpiExpAltLoading: getKpiExpAltLoading(state),
  kpiPriceTotalLoading: getKpiPriceTotalLoading(state),
  kpiPriceEnergyLoading: getKpiPriceEnergyLoading(state),
  kpiPriceTransportLoading: getKpiPriceTransportLoading(state),
  kpiPriceTaxesLoading: getKpiPriceTaxesLoading(state),
  kpiPriceAltLoading: getKpiPriceAltLoading(state),
  pieGraph: getPieGraph(state),
  colGraph: getColGraph(state),
})

export default withTranslation()(connect(mapStateToProps, actions)(Gas))
