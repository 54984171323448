import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { NavLink, Badge } from 'reactstrap'

import { closeSearchPanel } from '../../../actions/searchPanel'
import { getSearchPanelOpen } from '../../../reducers/searchPanel'
import { getCountFavorites } from '../../../reducers/favorites'
import { getCountSelected } from '../../../reducers/currentSelection'

class SidebarNavLink extends Component {
  shouldComponentUpdate(nextProps) {
    /**
     * We update the view only if the filter pqnel is closed
     * or if one counters has been updated
     */
    return (
      !nextProps.searchPanelOpen ||
      !nextProps.searchPanelOpen &
        (nextProps.countSelected !== this.props.countSelected ||
          nextProps.countFavorites !== this.props.countFavorites)
    )
  }
  render() {
    const {
      tabId,
      text,
      activeTab,
      toggleTab,
      countSelected,
      countFavorites,
      dispatch,
      closeSearchPanel,
    } = this.props
    return (
      <NavLink
        className={activeTab === tabId ? 'active' : ''}
        onClick={() => {
          toggleTab(tabId)
          dispatch(closeSearchPanel)
        }}
      >
        {text}
        <Badge color="primary">{tabId === 'SELECTION' ? countSelected : countFavorites}</Badge>
        {activeTab === tabId ? <div className="active" /> : null}
      </NavLink>
    )
  }
}

const mapStateToProps = (state) => ({
  searchPanelOpen: getSearchPanelOpen(state),
  countSelected: getCountSelected(state),
  countFavorites: getCountFavorites(state),
  closeSearchPanel: closeSearchPanel(),
})

SidebarNavLink.propTypes = {
  tabId: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  activeTab: PropTypes.string.isRequired,
  toggleTab: PropTypes.func.isRequired,
  searchPanelOpen: PropTypes.bool.isRequired,
  countSelected: PropTypes.number,
  countFavorites: PropTypes.number.isRequired,
  dispatch: PropTypes.func.isRequired,
  closeSearchPanel: PropTypes.func.isRequired,
}

export default connect(mapStateToProps)(SidebarNavLink)
