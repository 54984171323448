import React from 'react'
import ReactTable from 'react-table'
import withFixedColumns from 'react-table-hoc-fixed-columns'
import moment from 'moment'
import flatten from 'lodash/flatten'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { compose, withHandlers, lifecycle } from 'recompose'

import { fetchConso } from '../../actions/powerConsumption'
import { getConso, getConsoIsLoading } from '../../reducers/powerConsumption'
import { getTypeDonnee } from '../../reducers/typeDonnee'
import { getStartDate, getEndDate } from '../../reducers/dateRange'
import {
  getCurrentVisibleElecPeesIds,
  getCurrentVisibleGasPeesIds,
} from '../../reducers/currentSelection'

import { decimalFormat } from '../../helpers/helpers'
import { adaptPageSizeOptions, translations } from '../../helpers/react-table'

import { TableLoader } from '../common/Loader'
import CSVButton from '../common/CSVButton/CSVButton'

import styles from './ConsoTable.module.scss'

const ReactTableFixedColumns = withFixedColumns(ReactTable)

const borderRightStyle = {
  borderRight: '1px solid #ccc',
}

function createCSV(t, columns, tableData) {
  const header1 = flatten(
    columns.map(({ Header, columns }) =>
      Array(columns.length).fill(typeof Header === 'string' ? Header.toUpperCase() : '')
    )
  )

  const header2 = flatten(columns.map(({ columns }) => columns.map(({ Header }) => Header)))

  const accessors = flatten(columns.map(({ columns }) => columns.map(({ accessor }) => accessor)))

  const body = tableData.map((line) => accessors.map((a) => line[a]))

  return [header1, header2, ...body]
}

function ConsoTable({ t, data, isLoading, types, energy }) {
  const consos =
    data.length === 0 || Object.values(data[0].Compteurs).length === 0
      ? []
      : Object.values(data[0].Compteurs)[0].Consommations

  const periods = consos.map(({ Periode }) => {
    const m = moment(Periode)
    return {
      timestamp: m.format('x'),
      formatted: m.format(types.length > 1 ? 'MMMM YYYY' : 'MMM YYYY'),
    }
  })

  const columns = [
    {
      Header: () => <span>&nbsp;</span>,
      fixed: 'left',
      columns: [
        {
          Header: t('powerConsumption.table.site'),
          accessor: 'site',
          width: 130,
        },
        {
          Header: t('powerConsumption.table.pee'),
          accessor: 'pee',
          width: 200,
        },
        {
          Header: t('powerConsumption.table.centreCout'),
          accessor: 'centreCout',
          width: 150,
        },
      ],
    },
    ...periods.map(({ timestamp, formatted }) => ({
      Header: formatted,
      headerStyle: {
        ...borderRightStyle,
        textTransform: 'uppercase',
      },
      columns: types.map((type, index) => {
        const styles =
          index < types.length - 1
            ? {}
            : {
                style: borderRightStyle,
                headerStyle: borderRightStyle,
              }
        return {
          Header: t(`powerConsumption.table.${type.toLowerCase()}`),
          accessor: `${timestamp}-${type}`,
          className: 'text-right',
          minWidth: 90,
          ...styles,
        }
      }),
    })),
  ]

  const tableData = flatten(
    data.map(({ Nom, Compteurs }) =>
      Object.values(Compteurs).map(({ PeeClef, CodeSite, Consommations }) => ({
        site: Nom,
        pee: PeeClef,
        centreCout: CodeSite,
        ...Consommations.reduce((acc, { Periode, VolumeFac, Volume, VolumeN1, VolumeBudget }) => {
          const ts = moment(Periode).format('x')
          if (types.includes('FacturePrevu')) acc[`${ts}-FacturePrevu`] = decimalFormat(Volume)
          if (types.includes('Facture')) acc[`${ts}-Facture`] = decimalFormat(VolumeFac)
          if (types.includes('FactureN1')) acc[`${ts}-FactureN1`] = decimalFormat(VolumeN1)
          if (types.includes('Budget')) acc[`${ts}-Budget`] = decimalFormat(VolumeBudget)
          return acc
        }, {}),
      }))
    )
  )

  const energyLibelle =
    energy === 'ELE'
      ? t('powerConsumption.loadCurve.energy.elec')
      : energy === 'GAZ'
      ? t('powerConsumption.loadCurve.energy.gas')
      : ''

  const csvFilename = `${t('powerConsumption.graphTitle')} - ${energyLibelle}.csv`

  return (
    <>
      <div className={styles.header}>
        <div>{t('powerConsumption.unit')} : MWh</div>
        <CSVButton filename={csvFilename} createContent={() => createCSV(t, columns, tableData)} />
      </div>
      <ReactTableFixedColumns
        data={tableData}
        columns={columns}
        defaultPageSize={10}
        pageSizeOptions={adaptPageSizeOptions(tableData)}
        loading={isLoading}
        LoadingComponent={TableLoader}
        className={`-striped -highlight ${styles.table}`}
        css={{
          '.rt-tbody': {
            maxHeight: '350px',
          },
        }}
        {...translations(t)}
      />
    </>
  )
}

const mapState = (state, ownProps) => ({
  data: getConso(state),
  isLoading: getConsoIsLoading(state),
  types: getTypeDonnee(state),
  ids:
    ownProps.energy === 'ELE'
      ? getCurrentVisibleElecPeesIds(state)
      : ownProps.energy === 'GAZ'
      ? getCurrentVisibleGasPeesIds(state)
      : [],
  startDate: getStartDate(state),
  endDate: getEndDate(state),
})

const mapDispatch = {
  fetchConso,
}

const createPayload = (props) => ({
  Type: 'CPT',
  Ids: props.ids,
  Debut: props.startDate,
  Fin: props.endDate,
  Commodite: props.energy,
  TypeDonnees: {
    FacturePrevu: true,
    Facture: true,
    FactureN1: true,
    Budget: true,
  },
})

export default compose(
  withTranslation(),
  connect(mapState, mapDispatch),
  withHandlers({
    fetchData: (props) => () => {
      props.fetchConso(createPayload(props))
    },
  }),
  lifecycle({
    componentDidMount() {
      this.props.fetchData()
    },
    componentDidUpdate(prevProps) {
      if (JSON.stringify(createPayload(prevProps)) !== JSON.stringify(createPayload(this.props))) {
        this.props.fetchData()
      }
    },
  })
)(ConsoTable)
